import Footer from '../components/footer/Footer'
import Header from '../components/header/Header'
import InnerPageBanner from '../components/shared/InnerPageBanner';
import aboutBanner from "../assets/Images/EVChargingStationImages.webp";
import ContactInfo from '../components/contact_info/ContactInfo';
import React from "react";
import SEO from '../common/SEO';
import Images from '../components/images/Images';

const Image = () => {

    return (
        <>
            <SEO title='Visual Gallery of EV Charging Stations | MyEVPoint' description='Explore a visual gallery featuring stunning images of electric vehicle charging stations. Get inspired and informed. Visit myevpoint.in now!' />
            <Header />
            <InnerPageBanner title='Images' maxWidth='472px' innerPageBanner={aboutBanner} />
            <Images/>
            <ContactInfo />
            <Footer />

        </>
    )
}

export default Image;
