import React from 'react'


const Refund = () => {
    return (
        <>

            <section>

                <div className='container !max-w-[800px] mx-auto pb-20 pt-8 px-6 md:px-4 '>
                    {/* <div className="xl:mb-2 text-center">
                        <h1 className="text-center text-3xl text-black font-semibold md:text-4xl lg:text-5xl mb-10 md:mb-0">Refund Policy</h1>

                    </div> */}
                    <p className='text-[16px] mt-8'>{data.description}</p>
                    <div className='py-6'>
                        {data.conditions.map((index, i) => (
                            <div key={i}>
                                <h2 className='mb-1 text-[28px]'>{index.header}</h2>
                                <p
                                    className='text-[16px] para-design mb-5'
                                    dangerouslySetInnerHTML={{ __html: index.desc }}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </section>

        </>
    )
}

const data = {
    description: "At my EV point, we guarantee customer satisfaction. As we offer transparency in our services at myEV Point, we recommend that you carefully go through our Terms and Conditions.",
    conditions: [
        {
            header: "Refund Eligibility",
            desc: "In many instances, users ask for a refund, and we never disappoint our customers unless they meet the required criteria. First of all, users have to understand that requests for refunds must be made within the specified days of the purchase of the services, as mentioned in company rules. Apart from this, on requesting a refund, users must provide supporting evidence, and if it is found to be genuine, only then a refund is provided."
        },
        {
            header: "Requesting a refund",
            desc: "In case you are requesting a refund, kindly contact our customer support team at <a href='mailto:info@myevpoint.in' style='color: #0B7132;'>info@myevpoint.in</a>. You can do it through various customer support channels. Submit your request along with the evidence."

        },
        {
            header: "Documentation",
            desc: "Documents like screenshots of payments along with the date and time of the charging session are to be submitted while requesting a refund. After the completion of verification of documentation, if the request is found to be genuine, you will be provided with your refund using the original payment method used by you."
        },
        {
            header: "Changes in refund policy",
            desc: "Company is entitled to make changes in the refund policy at any time and users will be notified about the same"
        },
        {
            header: "Limitations",
            desc: "Refund policy is applicable for certain services or products. So, we request you to read the details of your product or service before initiating any transaction."
        },
        {
            header: "Our commitment",
            desc: "Above all this, we are always eager to provide the best of the services to our customers. Our team of experts  is always there at your service to provide you with the required help.<br>As mentioned above, you are considered to be in agreement of our terms and conditions while using our EV charging services. So, you are advised to read them thoroughly and understand everything. We are always committed to providing a positive user experience."
        },


    ]
};

export default Refund;