import React from "react";
import Header from '../components/header/Header'
import Footer from "../components/footer/Footer";
import Sitemap from "../common/Sitemap";
import SEO from '../common/SEO';


const SiteMap = () => {
    return (
        <>
        <SEO title='MyEVPoint Sitemap - Find All Pages Easily' description='The Sitemap of MyEVPoint to easily navigate through the website and find the information you need about electric vehicles charging stations.' keywords="electric vehicles charging stations, EV charging stations"/>
            <Header />
            <Sitemap />
            <Footer />
        </>
    )
}

export default SiteMap;