import React, { useEffect, useState, useRef } from "react";
import { IoSearch } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import servicesData from "../../data/cities.json";
import { MdSearchOff } from "react-icons/md";

const Search = () => {
    const [filteredCities, setFilteredCities] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const navigate = useNavigate(); // Replace useHistory with useNavigate

    useEffect(() => {
        const filteredServiceResult = Array.isArray(servicesData) && servicesData?.length > 0
            ? servicesData?.filter((serviceItem) => {
                const searchTermLower = searchTerm.toLowerCase();
                return (
                    serviceItem?.cityname.toLowerCase().startsWith(searchTermLower)
                );
            })
            
            : [];
        setFilteredCities(filteredServiceResult);
                        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm, servicesData]);

    const searchDropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchDropdownRef.current && !searchDropdownRef.current.contains(event.target)) {
                setSearchTerm('');
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [searchDropdownRef]);

    const handleSuggestionClick = (cityName) => {
        navigate(`/cities/${cityName}`); // Use navigate instead of history.push
        setSearchTerm(""); // Clear the search term
    };

    const handleSearchEnter = (e) => {
        if (e.key === 'Enter' && searchTerm.trim() !== "" && filteredCities.length > 0) {
            navigate(`/cities/${filteredCities[0]?.types?.replace(/\s+/g, "-").toLowerCase()}`); // Use navigate instead of history.push
            setSearchTerm(""); // Clear the search term
        }
    };

    const handleSearchIconClick = () => {
        if (searchTerm.trim() !== "" && filteredCities.length > 0) {
            navigate(`/cities/${filteredCities[0]?.types?.replace(/\s+/g, "-").toLowerCase()}`); // Use navigate instead of history.push
            setSearchTerm(""); // Clear the search term
        }
    };

    return (
        <>
            <div className="w-full md:py-[70px] max-md:py-[40px] ">
                <div className="container !max-w-[900px] mx-auto   ">
                    <form>
                        <div className="flex w-full relative ">
                            <input
                                type="text"
                                id="search-dropdown"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                onKeyDown={handleSearchEnter}
                                className="border focus:outline-none bg-[#fff] mb-2 text-[#000] md:h-[50px] max-md:h-[40px] rounded-full block w-full md:ps-5 max-md:ps-3 md:py-[8px] max-md:py-[6px] md:pe-[50px] max-md:pe-[40px]"
                                placeholder="Search for your city"
                            />
                            {searchTerm && (
                                <div className='absolute z-20   search-fill-info bg-[#0B7132] mt-[42px] md:mt-[50px] w-full ' ref={searchDropdownRef}>
                                    {filteredCities.length === 0 ? (
                                        <ul>
                                            <li>
                                                <div className='block text-white px-[10px] py-[10px] border-b flex gap-[5px] '>
                                                    <MdSearchOff className='h-5 w-6 cursor-pointer' />
                                                    Not Found
                                                </div>
                                            </li>
                                        </ul>
                                    ) : (
                                        <ul>
                                            {filteredCities?.map((serviceItem) => (
                                                <li key={serviceItem?._id} className='w-full'>
                                                    <div
                                                        className=' block text-white px-[10px] py-[10px] border-b hover:text-[#ffffff]'
                                                        onClick={() => handleSuggestionClick(serviceItem?.types?.replace(/\s+/g, "-").toLowerCase())}
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        {serviceItem?.title}
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            )}
                            <div
                                className="absolute md:h-[50px] max-md:h-[40px] md:min-w-[50px] max-md:min-w-[40px] py-[8px] right-0 top-0 flex items-center justify-center text-white bg-[#0B7132] rounded-full cursor-pointer"
                                onClick={handleSearchIconClick}
                            >
                                <IoSearch className='md:text-[18px] max-md:text-[16px]' />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default Search;
