import React, { useState, useRef, useEffect } from 'react'
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { IoSearch } from "react-icons/io5";
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import servicesData from "../data/services.json"

const Searchs = () => {


    const [blogData, setBlogData] = useState([]);
    const [filteredBlogs, setFilteredBlogs] = useState([]);
    const [filteredServices, setFilteredServices] = useState([]);
    const [searchTerm, setSearchKey] = useState("");

   


    // const handleKeyPress = (event) => {
    //     if (event.key === 'Enter') {
    //         setSearchedData({ blogs: filteredBlogs, services: filteredServices });
    //         setSearchTerm(searchTerm);
    //         setFilteredServices([]);
    //         setSearchKey("");
    //         navigate("/search", {
    //             state: {
    //                 searchTerm: searchTerm
    //             }
    //         });
    //     }
    // };






    const fetchBlogs = async () => {
        const response = await axios.get(`${process.env.REACT_APP_URL}/user/getBlogs`);
        setBlogData(response.data.data)
    }


    useEffect(() => {
        fetchBlogs()
    }, [])







    useEffect(() => {
        const filteredResult =
            Array.isArray(blogData) && blogData?.length > 0
                ? blogData?.filter((blogItem) =>
                    (blogItem.isActive === true) &&
                    (blogItem.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        blogItem.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        blogItem.author.toLowerCase().includes(searchTerm.toLowerCase()))
                )
                : [];
        setFilteredBlogs(filteredResult);
    }, [searchTerm, blogData]);

    useEffect(() => {
        const filteredServiceResult =
            Array.isArray(servicesData) && servicesData?.length > 0
                ? servicesData?.filter((serviceItem) => {
                    const searchTermLower = searchTerm.toLowerCase();
                    const additionalInfoMatches = serviceItem?.additionalInfo.some(
                        (info) =>
                            info.title.toLowerCase().includes(searchTermLower) ||
                            info.description.toLowerCase().includes(searchTermLower)
                    );
                    const slowFeaturesMatches = serviceItem?.slowFeatures?.some(
                        (feature) => feature.text.toLowerCase().includes(searchTermLower)
                    );
                    const fastFeaturesMatches = serviceItem?.fastFeatures?.some(
                        (feature) => feature.text.toLowerCase().includes(searchTermLower)
                    );
                    const singleFeaturesMatches = serviceItem?.singleFeatures?.some(
                        (feature) => feature.text.toLowerCase().includes(searchTermLower)
                    ); const dualFeaturesMatches = serviceItem?.fastFeatures?.some(
                        (feature) => feature.text.toLowerCase().includes(searchTermLower)
                    );

                    return (
                        serviceItem?.short.toLowerCase().includes(searchTermLower) ||
                        serviceItem?.type.toLowerCase().includes(searchTermLower) ||
                        serviceItem?.title.toLowerCase().includes(searchTermLower) ||
                        serviceItem?.desc1.toLowerCase().includes(searchTermLower) ||
                        serviceItem?.summary.toLowerCase().includes(searchTermLower) ||
                        additionalInfoMatches ||
                        slowFeaturesMatches ||
                        fastFeaturesMatches ||
                        singleFeaturesMatches ||
                        dualFeaturesMatches
                    );
                })
                : [];
        setFilteredServices(filteredServiceResult);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm, servicesData]);






    const searchDropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchDropdownRef.current && !searchDropdownRef.current.contains(event.target)) {
                setSearchKey('');
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [searchDropdownRef]);

    function resetHandler() {
        setSearchKey("");
        setFilteredServices([]);
    }


    const { state } = useLocation();
    const searchTerm1 = state?.searchTerm; // Accessing the searchTerm from state
    useEffect(() => {
        if (searchTerm1) {
            setSearchKey(searchTerm1);
        }
    }, [searchTerm1]);


    return (
        <>
            <Header />
            <div className='w-full md:py-[70px] max-md:py-[40px]'>
            <form>
                <div className="container mx-auto px-2">
                    <div className="relative w-full max-w-[992px] mx-auto" ref={searchDropdownRef}>
                        <input type="text" id="search-dropdown" className="focus:outline-none bg-[#fff] text-[#000] md:h-[40px] max-md:h-[30px] rounded-full block w-full md:px-5 max-md:px-3 md:py-[8px] max-md:py-[6px] pe-[40px] border border-gray-400" placeholder="Search..."
                            value={searchTerm}
                            onChange={(e) => setSearchKey(e.target.value)}
                            // onKeyDown={handleKeyPress}
                        />
                        {/* 
                        {searchTerm && (
                            <div className='absolute z-20 search-fill-info bg-[#000]'>
                                {filteredBlogs.length === 0 && filteredServices.length === 0 ? (
                                    <ul>
                                        <li>
                                            <div className='block text-white px-2 py-2 border-b flex w-56 justify-center'>
                                                <MdSearchOff className='h-5 w-6 cursor-pointer' />
                                                Not Found
                                            </div>
                                        </li>
                                    </ul>
                                ) : (
                                    <ul>
                                        {filteredBlogs?.map((blogItem) => (
                                            <li key={blogItem?._id} className='w-56 text-center'>
                                                <Link
                                                    onClick={resetHandler}
                                                    className='block text-white px-2 py-2 border-b hover:text-[#0B7132]'
                                                    to={`/blogs/${blogItem?.handle?.replace(/\s+/g, "-").toLowerCase()}`}
                                                >
                                                    {blogItem?.title.slice(0, 20)}...
                                                </Link>
                                            </li>
                                        ))}
                                        {filteredServices?.map((serviceItem) => (
                                            <li key={serviceItem?._id} className='w-56 text-center'>
                                                <Link
                                                    onClick={resetHandler}
                                                    className='block text-white px-2 py-2 border-b hover:text-[#0B7132]'
                                                    to={`/services/${serviceItem?.type?.replace(/\s+/g, "-").toLowerCase()}`}
                                                >
                                                    {serviceItem?.title.slice(0, 20)}...
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        )} */}


                        <div type="submit" className="absolute  md:h-[40px] max-md:h-[30px] md:min-w-[40px] max-md:min-w-[30px] py-[8px] right-0 top-0 flex items-center justify-center text-white bg-[#0B7132] rounded-full">
                            <IoSearch className='md:text-[18px] max-md:text-[16px]' />
                        </div>

                    </div>
                </div>
            </form>




            {/* {filteredBlogs?.map((blogItem) => (
                <div className="w-full pt-10 pb-[5px] px-40">
                    <div className="container mx-auto ">
                        <div className="bg-[#fff] rounded p-4 shadow-[0px_0px_25px_0px_rgba(0,0,0,0.15)]">
                            <div className="lg:p-4">
                                <div key={blogItem?._id} >
                                    <Link
                                        onClick={resetHandler}
                                        className='block text-black px-2 py-2'
                                        to={`/blogs/${blogItem?.handle?.replace(/\s+/g, "-").toLowerCase()}`}
                                    >
                                        <h4 className="mb-3 cursor-pointer hover:text-[#0B7132]"> {blogItem?.title.slice(0, 20)}...</h4>

                                    </Link>
                                </div>
                                <p>MyEV point has set a benchmark in Delhi by setting up 50 charging points at multiple strategic locations.</p>
                            </div>
                        </div>
                    </div>
                </div>
            ))} */}

            {/* {filteredServices?.map((serviceItem) => (
                <div className="w-full pt-10 pb-[5px] px-40">
                    <div className="container mx-auto ">
                        <div className="bg-[#fff] rounded p-4 shadow-[0px_0px_25px_0px_rgba(0,0,0,0.15)]">
                            <div className="lg:p-4">
                                <div key={serviceItem?._id} >
                                    <Link
                                        onClick={resetHandler}
                                        className='block text-black px-2 py-2 '
                                        to={`/services/${serviceItem?.type?.replace(/\s+/g, "-").toLowerCase()}`}
                                    >
                                        <h4 className="mb-3 cursor-pointer hover:text-[#0B7132]">{serviceItem?.title.slice(0, 20)}...</h4>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            ))} */}
            
            {filteredBlogs.length === 0 && filteredServices.length === 0 ? (
                <div className='container mx-auto px-2'>
                    <div className="text-center md:mt-[50px] max-md:mt-[30px]">
                        <h4 className="cursor-pointer hover:text-[#0B7132]">Not found results</h4>
                    </div>
                </div>) : (
                <>
                <div className="w-full md:mt-[50px] max-md:mt-[35px]">
                    <div className="container mx-auto px-2">
                    {filteredBlogs?.map((blogItem) => ( 
                                <div className="bg-[#fff] rounded p-4 shadow-[0px_0px_15px_0px_rgba(0,0,0,0.15)] md:mb-[30px] max-md:mb-[20px] last:mb-[0px]">
                                    <div className="lg:p-4">
                                        <div key={blogItem?._id} >
                                            <Link
                                                onClick={resetHandler}
                                                className='block text-black'
                                                to={`/blogs/${blogItem?.handle?.replace(/\s+/g, "-").toLowerCase()}`}
                                            >
                                                <h4 className="mb-3 cursor-pointer hover:text-[#0B7132]"> {blogItem?.title.slice(0, 20)}...</h4>
                                            </Link>
                                        </div>
                                        <p>MyEV point has set a benchmark in Delhi by setting up 50 charging points at multiple strategic locations.</p>
                                    </div>
                                </div>
                    ))}
                    
                    {filteredServices?.map((serviceItem) => (
                                <div className="bg-[#fff] rounded p-4 shadow-[0px_0px_15px_0px_rgba(0,0,0,0.15)] md:mb-[30px] max-md:mb-[20px] last:mb-[0px]">
                                    <div className="lg:p-4">
                                        <div key={serviceItem?._id} >
                                            <Link
                                                onClick={resetHandler}
                                                className='block text-black'
                                                to={`/services/${serviceItem?.type?.replace(/\s+/g, "-").toLowerCase()}`}
                                            >
                                                <h4 className="mb-3 cursor-pointer hover:text-[#0B7132]">{serviceItem?.title.slice(0, 20)}...</h4>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                    ))}
                    </div>
                </div>
                </>
            )}

            </div>
            <Footer />

        </>
    );
};

export default Searchs;
