import React from 'react';
import Header from "../components/header/Header";
import Refund from "../components/termsPrivacy/Refund";
import Footer from "../components/footer/Footer";
import SEO from "../common/SEO";
import aboutBanner from "../assets/Images/Refund&Policy.webp";
import InnerPageBanner from '../components/shared/InnerPageBanner';
const Refunds = () => {
  

    return (
        <>

            <SEO title='MyEvPoint Refund Policy | Electric Vehicle Payment Policies' description='MyEvPoint electric vehicle refund policy for clear guidelines. Learn about our payment process and ensure a seamless experience with our EV charging services.' />
            <Header />
            <InnerPageBanner title='Refund Policy' maxWidth='472px' innerPageBanner={aboutBanner} />
            <Refund />
            <Footer />
        </>
    )
}
export default Refunds;