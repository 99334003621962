import React from "react";
import Header from "../components/header/Header";
import Banner from "../components/home/Banner";
import AboutUs from "../components/home/AboutUs";
import Features from "../components/home/Features";
import Strategy from "../components/home/Strategy";
import Charges from "../components/home/Charges";
import Footer from "../components/footer/Footer";
import Testimonials from "../components/testimonials/Testimonials";
import ContactInfo from "../components/contact_info/ContactInfo";
import Ourgallery from "../components/home/OurGallery";
import Ournewsletter from "../common/OurNewsletter";
import Blogsection from "../common/BlogSection";
import SEO from "../common/SEO";
import Content from "../components/home/content";

const Home = () => {
    return (
        <>
            <SEO title="Best EV charging station service providers: MyEV Point"
                description="Best EV charging station providers offering reliable and efficient charging services for your EV. Choose the best provider for your electric vehicle needs." keywords="ev charging stations, ev charging stations near me, ev charging station providers, ev charging service providers, electric charging stations, electric vehicle charging, ev charging stations cost, electric vehicle charging station, electric vehicle charging station cost, public ev charging stations, electric cars charging stations, electric vehicle charging cost, electric vehicle charging near me, fast charging stations near me, electric vehicle chargers near me, ev fast charging stations, ev car charging station, ev charging services, electric vehicle station near me, electric car stations"
                schema={`
    {
      "@context": "https://schema.org/",
      "@type": "WebSite",
      "name": "myevpoint",
      "url": "https://www.myevpoint.in/",
      "potentialAction": {
        "@type": "SearchAction",
        "target": "https://www.myevpoint.in/?search={search_term_string}",
        "query-input": "required name=search_term_string"
      }
    }
    `} 
    
    organization={`
    {         
      "@context": "https://schema.org/",         
      "@type": "Organization",         
      "@id": "https://www.myevpoint.in/#Organization",         
      "url": "https://www.myevpoint.in/",         
      "legalName": "My Ev Point",         
      "name": "myevpoint",         
      "description": "Best EV charging station providers offering reliable and efficient charging services for your EV. Choose the best provider for your electric vehicle needs.",         
      "logo": "https://www.myevpoint.in/static/media/myEV_logo.9baa1dd0c29762718e22.webp",         
      "telephone": "+91 9592-595975",         
      "email": "mailto:info@myevpoint.in",         
      "address": {             
        "@type": "PostalAddress",             
        "streetAddress": "B-70, Phase 7, Sector 74",             
        "addressLocality": "SAS Nagar",             
        "addressRegion": "Punjab",             
        "addressCountry": "IN",             
        "postalCode": "160055"        
      },         
      "sameAs": [             
        "https://www.facebook.com/myevpoint",          
        "https://www.youtube.com/@MyEVPoint",             
        "https://www.instagram.com/myevpoint/",             
        "https://www.linkedin.com/company/my-ev-point/",           
        "https://x.com/myevpoint"       
      ] 
    } 
    `}

    business={`
    {         
      "@context": "https://schema.org/",         
      "@type": "LocalBusiness",         
      "@id": "https://www.myevpoint.in/#LocalBusiness",         
      "url": "https://www.myevpoint.in/",          
      "name": "My Ev Point",
      "description": "Best EV charging station providers offering reliable and efficient charging services for your EV. Choose the best provider for your electric vehicle needs.",
      "logo": "https://www.myevpoint.in/static/media/myEV_logo.9baa1dd0c29762718e22.webp",
      "telephone": "+91 9592-595975",
      "email": "info@myevpoint.in",
      "address": {             
        "@type": "PostalAddress",             
        "streetAddress": "Hall 3, IT Wing, B-70, beside Falcon Enterprises, Phase 7, Industrial Area, Sector 74, Sahibzada Ajit Singh Nagar",             
        "addressLocality": "Mohali",             
        "addressRegion": "PB",             
        "addressCountry": "India",             
        "postalCode": "160055"        
      }
    } 
    `}

    product={`
    {
        "@context": "https://schema.org",
        "@type": "ItemList",
        "itemListElement": [
            {
                "@type": "ListItem",
                "position": 1,
                "item": {
                    "@type": "Product",
                    "name": "7.4 kW AC Charging Machine",
                    "image": [
                        "https://www.myevpoint.in/static/media/EV%20Machine7.4.60c2af780f9ecd9580df.webp",
                        "https://www.myevpoint.in/static/media/MyEVMachine7.4.6ac02f3fedc312a88bfd.webp"
                    ],
                    "description": "Buy Efficient and reliable 7.4 kW AC Charging station for electric vehicles, perfect for home and public use. Fast charging, easy installation, and user-friendly design",
                    "brand": {
                        "@type": "Organization",
                        "name": "MyEVPoint"
                    },
                    "offers": {
                        "@type": "Offer",
                        "priceCurrency": "INR",
                        "price": "0"
                    }
                }
            },
            {
                "@type": "ListItem",
                "position": 2,
                "item": {
                    "@type": "Product",
                    "name": "22 kW AC Charging Machine",
                    "image": [
                        "https://www.myevpoint.in/static/media/My%20EV%20Point%2022%20KW%20Machine.7a8e03bc2157d03e07ff.webp",
                        "https://www.myevpoint.in/static/media/22%20KW%20Electric%20Vehicle%20Machine.192f1f0f38c352bda9c2.webp"
                    ],
                    "description": "22 kW AC Charging Machine for electric vehicles, ideal for commercial and high-traffic areas. Fast and efficient charging with user-friendly features.",
                    "brand": {
                        "@type": "Organization",
                        "name": "MyEVPoint"
                    },
                    "offers": {
                        "@type": "Offer",
                        "priceCurrency": "INR",
                        "price": "0"
                    }
                }
            },
            {
                "@type": "ListItem",
                "position": 3,
                "item": {
                    "@type": "Product",
                    "name": "30 kW DC Fast EV Charging Machine",
                    "image": [
                        "https://www.myevpoint.in/static/media/Untitled%20design%20(82).6e13d660900febdc5942.webp",
                        "https://www.myevpoint.in/static/media/My%20EV%20Point%2030%20KW%20Charging%20Station%20Machine.fa3b3bb149c33d49fe78.webp"
                    ],
                    "description": "30 kW DC EV Fast Charging Machine for rapid electric vehicle charging. Ideal for busy locations, providing quick and reliable service.",
                    "brand": {
                        "@type": "Organization",
                        "name": "MyEVPoint"
                    },
                    "offers": {
                        "@type": "Offer",
                        "priceCurrency": "INR",
                        "price": "0"
                    }
                }
            },
            {
                "@type": "ListItem",
                "position": 4,
                "item": {
                    "@type": "Product",
                    "name": "60 kW DC Fast EV Charging Machine",
                    "image": [
                        "https://www.myevpoint.in/static/media/My%20EV%20Point%2060%20KW%20Machine.b54bd35ea5d363d2667d.webp",
                        "https://www.myevpoint.in/static/media/Electric%20Vehicle%20Machine.ec2a1a227fa120c4d97c.webp"
                    ],
                    "description": "Ultra-fast 60 kW DC Charging Machine for electric vehicles, ensuring quick and efficient charging. Perfect for high-traffic areas, providing reliable performance.",
                    "brand": {
                        "@type": "Organization",
                        "name": "MyEVPoint"
                    },
                    "offers": {
                        "@type": "Offer",
                        "priceCurrency": "INR",
                        "price": "0"
                    }
                }
            }
        ]
    }
    `}

    faqs={`
            {
            "@context": "https://schema.org/", 
            "@type": "FAQPage", 
            "mainEntity": [{
              "@type": "Question", 
              "name": "How many types of EV charging stations are provided by MyEV Point?",
              "acceptedAnswer": { 
                "@type": "Answer",
                "text": "Three types of EV charging station services are available called Level 1, Level 2 and Level 3 (Tesla supercharging)."
              } 
            },{
              "@type": "Question", 
              "name": "How much does it cost to charge an Electric Vehicle from MyEV point charging station?",
              "acceptedAnswer": { 
                "@type": "Answer",
                "text": "Although it totally depends upon the rates of electricity whether you charge at a station or home, some charging station providers charge according to start rate or sometimes rate per minute."
              }  
            
    },{
    "@type": "Question",
    "name": "How can I find myEV point charging station near to me?",
    "acceptedAnswer": {
    "@type": "Answer",
    "text": "It is very easy, as we have established a proper network which could easily be accessed through our application."
    }
    
    },{
    "@type": "Question",
    "name": "Can I charge my vehicle in the rain with the MyEV Point charging station?",
    "acceptedAnswer": {
    "@type": "Answer",
    "text": "Definitely yes because our charging ports are weather resistant allowing users to charge their EVs in any weather condition."
    }
    
    },{
    "@type": "Question",
    "name": "How much maintenance do the chargers of MyEV point need?",
    "acceptedAnswer": {
    "@type": "Answer",
    "text": "Although these are advanced technology gadgets, they are very simple to maintain and thus require very little maintenance. "
    }
    
    },{
    "@type": "Question",
    "name": "How much area is required to install MyEV point charging station?",
    "acceptedAnswer": {
    "@type": "Answer",
    "text": "For a single charging point installation, a very small area of nearly 12 sq metres is enough."
    }
    
    },{
    "@type": "Question",
    "name": "Which charging station is better for EV in India?",
    "acceptedAnswer": {
    "@type": "Answer",
    "text": "It totally depends upon the needs of the user. If a user has to go on a long journey, he must give preference to Level 3 DC fast charging. On the other hand, if one uses a car for regular short journeys, one can go for level 1 and level 2. Which type of connectors are used by myEV point for AC charging? For AC charging, type 2 connectors are used."
    }
    
    },{
    "@type": "Question",
    "name": "Why is home EV charging given more preference over Public EV charging? ",
    "acceptedAnswer": {
    "@type": "Answer",
    "text": "It is basically due to the fact that in the past, the availability of public EV charging stations was very less and one another reason is the convenience of charging at home."
    }
    
    },{
    "@type": "Question",
    "name": "Is it common for EV charging to run out before I find a station?",
    "acceptedAnswer": {
    "@type": "Answer",
    "text": "There is no need to worry as the mechanism is the same as of gasoline powered old vehicles. Your vehicle will give you warning about the low charging and you can have a lot of time to find a charging station near you."
    }
    
    },{
    "@type": "Question",
    "name": "Why do EV charging stations need the internet?",
    "acceptedAnswer": {
    "@type": "Answer",
    "text": "They need the internet in order to set the schedules, track data and to charge users according to the electricity."
    }
    
    },{
    "@type": "Question",
    "name": "Any tips for best EV charging practice?",
    "acceptedAnswer": {
    "@type": "Answer",
    "text": "Yes, you should always make sure to keep your charging status between 25% and 75%."
    }
    
    },{
    "@type": "Question",
    "name": "Do all electric vehicles have a common plug?",
    "acceptedAnswer": {
    "@type": "Answer",
    "text": "For level 1 and level 2 charging, standard connectors are used but for DC charging connectors are different depending upon the make and model of your EV."
    }
    
    },{
    "@type": "Question",
    "name": "What is the maximum voltage for EV?",
    "acceptedAnswer": {
    "@type": "Answer",
    "text": "It typically ranges between 400-800V for lithium-ion battery packs."
    }
    
    },{
    "@type": "Question",
    "name": "What is an electric vehicle (EV) and how does it work?",
    "acceptedAnswer": {
    "@type": "Answer",
    "text": "An electric vehicle is a mode of transportation which uses electricity to power up rather than using fossil fuels like petrol and diesel. These vehicles are equipped with batteries that are charged with the help of electricity and provide power to the motor in the vehicle."
    }
    
    },{
    "@type": "Question",
    "name": "How environmentally friendly are electric vehicles? ",
    "acceptedAnswer": {
    "@type": "Answer",
    "text": "Electric vehicles come without tailpipes because they do not run on gasoline. So, they do not produce any harmful emissions. Moreover, the environmental effect can further be reduced by using renewable forms of generating electricity."
    }
    
    },{
    "@type": "Question",
    "name": "Can I charge my car on any charger?",
    "acceptedAnswer": {
    "@type": "Answer",
    "text": "It is important to check the compatibility of vehicles with different types of charger. Also, modern electric vehicles come up with a connector or an adapter which allow them to be charged at any station."
    }
    
    },{
    "@type": "Question",
    "name": "Which type of charging station is better for electric cars?",
    "acceptedAnswer": {
    "@type": "Answer",
    "text": "Although all types of electric vehicle charging stations are suitable for EVs, it depends on the driving habits and the needs of the drivers. If a person has to use it for a short distance, then level 1 is also suitable and if one wants faster charging, then one can go for level 2 also. Sometimes people want quick top-ups, so that time, people can choose DC fast charging for their EVs. "
    }
    
    },{
    "@type": "Question",
    "name": "How many types of EV charging stations are available at myEVPoint?",
    "acceptedAnswer": {
    "@type": "Answer",
    "text": "My EV point offers both AC and DC charging stations for its users."
    }
    
    },{
    "@type": "Question",
    "name": "How can I find nearby electric vehicle charging stations?",
    "acceptedAnswer": {
    "@type": "Answer",
    "text": "With myEV point application, it is very convenient to find your nearby electric vehicle charging station. Apart from this, electric vehicles nowadays come equipped with a navigation system which provides a database of nearby EV charging stations.	"
    }
    
    },{
    "@type": "Question",
    "name": "What safety considerations should I be aware of when using charging stations?",
    "acceptedAnswer": {
    "@type": "Answer",
    "text": "Before initiating charging, you should check for any signs of damage in the charging station as well as the charging cable. Apart from this, you should check whether the charging station is compatible with the vehicle or not."
    }
    
    },{
    "@type": "Question",
    "name": "Are there any special maintenance requirements for electric vehicles?",
    "acceptedAnswer": {
    "@type": "Answer",
    "text": "As electric vehicles do not have many moving parts, so requirement of maintenance is very less but users just need to take care of the performance of the battery as well get their vehicle inspected timely in order to make sure that all the components are in good condition."
    }
    
    },{
    "@type": "Question",
    "name": "How often do I need to service my electric vehicle?",
    "acceptedAnswer": {
    "@type": "Answer",
    "text": "Although the requirement of servicing is very less as compared to traditional vehicles, it still totally depends upon the recommendations of the manufacturer and your driving habits."
    }
    
    },{
    "@type": "Question",
    "name": "Can I charge my electric vehicle in rainy conditions?",
    "acceptedAnswer": {
    "@type": "Answer",
    "text": "Electric vehicle charging stations are weather-resistant, so they can be charged in any weather condition."
    }
            }]
        }
    `}


    />
            <Header />
            <Banner />
            <AboutUs />
            <Features />
            <Strategy />
            <Ourgallery />
            <Ournewsletter />
            <Charges />
            <Testimonials />
            <Blogsection />
            <Content />
            <ContactInfo />
            <Footer />
        </>
    )
};
export default Home;