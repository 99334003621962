import Addlocation from "../components/admin/location/Addlocation";
import PortalHeader from "../components/admin/portalheader/Portalheader";
import Alllocation from "../components/admin/location/Alllocation";
import React, { useState, useEffect } from "react";
// import AccountContext from "../utils/AccountContext"
import { useNavigate } from 'react-router-dom';

const Location = () => {
    const [trigger, setTrigger] = useState(0)
    const navigate = useNavigate();

    // const { login } = useContext(AccountContext);

    const storedUserInfo = sessionStorage.getItem('userInfo');


    useEffect(() => {
        if (!storedUserInfo) {
            navigate("/admin-login");
        }
    }, [storedUserInfo, navigate]);



    return (
        <>
            <PortalHeader>
                <Addlocation setTrigger={setTrigger} />
                <Alllocation trigger={trigger} />
            </PortalHeader>

        </>
    )
};

export default Location;