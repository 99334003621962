import React from "react";

const SingleBlogBanner = ({blogDetails}) => {
    console.log(blogDetails,"blogdetails")
    return (
        <>
            {/* <div className="relative sm:pt-[35%] pt-[40%] w-full">
                <img className="absolute top-0 left-0 w-full h-full object-center object-cover" src={blogDetails?.bannerimage}  alt={blogDetails?.banneralttag} title={blogDetails?.imagetitle} />
            </div> */}
        </>
    )
};

export default SingleBlogBanner