import Footer from '../components/footer/Footer'
import Header from '../components/header/Header'
import InnerPageBanner from '../components/shared/InnerPageBanner';
import aboutBanner from "../assets/Images/Blogs.webp";
import ContactInfo from '../components/contact_info/ContactInfo';
import BlogPage from '../components/blog/Blogs';
import React from "react";
import SEO from '../common/SEO';


const OurBlogs = () => {
    return (
        <>
            <SEO title='Stay informed with latest blogs of MyEV point' description='Get all the updates about our EV charging stations along with tips and techniques of charging with our recently written blogs on EV charging solutions.' />
            <Header />
            <InnerPageBanner title='Our Blogs' maxWidth='472px' innerPageBanner={aboutBanner} />
            <BlogPage />
            <ContactInfo />
            <Footer />
        </>
    )
}

export default OurBlogs;
