import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import SEO from "../common/SEO";
import InnerPageBanner from '../components/shared/InnerPageBanner';
import aboutBanner from "../assets/Images/bannerimage.webp";
import Franchisee from "../components/franchisse/Franchisse";

const Franchise = () => {
    return (
        <>
            <SEO title='Join the MyEVPoint Franchise Network | Start Your EV Charging Business' description='Explore franchise opportunities with MyEVPoint. Start your own EV charging business with our support and expertise. Join us today!' />
            <Header />
            <InnerPageBanner
                title='Franchise'
                innerPageBanner={aboutBanner}
            />
            <Franchisee/>
            <Footer />
        </>
    )
}
export default Franchise;