import React from 'react'

const Cancellations = () => {
    return (
        <>
            <section>
                <div className='container !max-w-[800px] mx-auto pb-20 pt-8 px-6 md:px-4 '>
                    {/* <div className="xl:mb-2 text-center">
                        <h1 className="text-center text-3xl text-black font-semibold md:text-4xl lg:text-5xl mb-10 md:mb-0">Cancellation Policy</h1>
                    </div> */}
                    <p className='text-[16px] mt-8'>{data.description}</p>
                    <div className='py-6'>
                        {data.conditions.map((index, i) => (
                            <div key={i}>
                                <h2 className='mb-1 text-[28px]'>{index.header}</h2>
                                <p
                                    className='text-[16px] para-design mb-5'
                                    dangerouslySetInnerHTML={{ __html: index.desc }}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </section>

        </>
    )
}

const data = {
    description: "As our customers are our pride, we don’t leave any stone unturned for their convenience. For their satisfaction, we have designed our cancellation policy so that they feel free while subscribing to us.",
    conditions: [
        {
            header: "Flexible options",
            desc: "According to your changing needs, there is a range of cancellation policies. We provide flexibility in every aspect and believe in offering hassle free user experience."
        },
        {
            header: "Cancellation Deadline",
            desc: "The responsibility of making cancellation within specified days lies on the users itself. We provide a particular deadline to our customers to cancel their services and we request our customers to finalise their decisions according to this for seamless operations. After the deadline, users would not be able to request any changes."
        },
        {
            header: "Transparent Communication",
            desc: "We believe in ensuring transparency in our services. So, we encourage our customers to communicate their decisions regarding cancellation immediately to our customer support team. It will give us time to address your queries and make sure you are getting seamless services."
        },
        {
            header: "User Support",
            desc: "Our customer support team is always at your service during the process of cancellation. You can communicate all your issues to them and clear your doubts. We are devoted to enhancing your user experience round the clock."
        },
        {
            header: "Termination",
            desc: "myEV point holds the right to cancel or terminate the services of those customers who do not abide by our specified terms and conditions. Users are also provided flexibility to cancel their services but with the specified time frame."
        },
        {
            header: "Feedback",
            desc: "Your feedback is paramount to improve our services. No matter what, be it positive or negative, we always appreciate your feedback and use it to enhance our offerings for providing better user experience.<br>In case, you have any concerns or doubts regarding myEV point, contact us at any time."
        },


    ]
};

export default Cancellations;