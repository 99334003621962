import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom"
import Header from '../components/header/Header'
import ServicesDetails from "../components/servicesDetail/ServicesDetail"
import Footer from '../components/footer/Footer'
import WhyUs from '../components/servicesDetail/WhyUS'
import InnerPageBanner from '../components/shared/InnerPageBanner';
// import aboutBanner from "../assets/Images/bannerimage.webp";
import ContactInfo from '../components/contact_info/ContactInfo';
import SEO from '../common/SEO'
import servicesData from "../data/services.json";

const ServicesDetail = () => {

    const { type } = useParams();

    const [serviceDetails, setServiceDetails] = useState(null);

    useEffect(() => {
        const selectedService = servicesData.find(service => service.type === type);
        setServiceDetails(selectedService);
    }, [type])
    
    return (
        <>
            <SEO title={serviceDetails?.metaTitle} description={serviceDetails?.metaDescription} keywords={serviceDetails?.keyword}/>
            <Header />
            <InnerPageBanner title={serviceDetails?.bannerTitle} maxWidth='472px' innerPageBanner={serviceDetails?.bannerimage}/>
            <ServicesDetails />
            <WhyUs />
            <ContactInfo />
            <Footer />
        </>
    )
}

// const servicesList = [
//     {
//         type: "ac-charging-station",
//         bannerTitle: 'AC Charger Service',
//         title: 'AC Charging Stations for Electric Vehicles - MyEVPoint',
//         description: 'Our Services for AC Charging Stations at MyEVPoint. Fast, reliable and eco-friendly charging solution for electric vehicles. Join the electric revolution today!'

//     },
//     {
//         type: "dc-charging-station",
//         bannerTitle: 'DC Charger Service',
//         title: 'DC Charging Stations | Fast EV Charging - My EV Point',
//         description: 'DC charging stations for electric cars at My EV Point. Fast and reliable charging solutions to power up your electric vehicle on the go.'
//     },

// ];

export default ServicesDetail;
