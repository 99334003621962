import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import SEO from "../common/SEO";
import InnerPageBanner from '../components/shared/InnerPageBanner';
import aboutBanner from "../assets/Images/bannerimage.webp";
import Shipping from "../components/shipping/Shipping";

const Shippings = () => {
    return (
        <>
            <SEO title='MyEVPoint Charging Station Shipping & Delivery Policy' description='Learn about MyEVPoint shipping and delivery policy. Find information on delivery times, shipping charges, and more. Get your EV charging products hassle-free.' />
            <Header />
            <InnerPageBanner
                title='Shipping & Delivery'
                innerPageBanner={aboutBanner}
            />
            <Shipping/>
            <Footer />
        </>
    )
}
export default Shippings;