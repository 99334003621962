import React from 'react'
import Footer from '../components/footer/Footer'
import Header from '../components/header/Header'
import ServicesList from '../components/services/ServicesList'
// import FAQs from '../components/services/FAQs'
import Strategy from '../components/services/Strategy'
import InnerPageBanner from '../components/shared/InnerPageBanner';
import aboutBanner from "../assets/Images/OurServiceAC&DCCurrent.webp";
import ContactInfo from '../components/contact_info/ContactInfo';
import SEO from '../common/SEO'

const Services = () => {
    return (
        <>
            <SEO title='AC and DC EV Charging Stations | MyEVPoint' description='AC and DC charging Stations for electric vehicles. Learn about different charging stations and methods to power your EV efficiently.' 
            keywords='ac and dc charging station, ac and dc charging of ev, ac and dc charging in electric vehicle, ac and dc car charging, ac and dc ev charging, electric car charging ac or dc, electric vehicle charging ac or dc'/>
            <Header />
            <InnerPageBanner title='Our Services' maxWidth='472px' innerPageBanner={aboutBanner} />
            <ServicesList />
            <Strategy />
            {/* <FAQs /> */}
            <ContactInfo />
            <Footer />
        </>
    )
}

export default Services;
