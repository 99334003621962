import PortalHeader from "../components/admin/portalheader/Portalheader";
import AddBlog from "../components/admin/blogs/Addblog";
import AllBlogs from "../components/admin/blogs/Allblogs";
import React, { useState, useEffect, useContext } from "react";
import AccountContext from "../utils/AccountContext"
import { useNavigate } from 'react-router-dom';


const Adminblog = () => {
    const [trigger, setTrigger] = useState(0)
    const navigate = useNavigate();
    const { login } = useContext(AccountContext);

    const storedUserInfo = sessionStorage.getItem('userInfo');


    useEffect(() => {
        if (!storedUserInfo) {
            navigate("/admin-login");
        }
    }, [storedUserInfo, navigate]);
    return (
        <>
        <PortalHeader>
            <AddBlog setTrigger={setTrigger}/>
            <AllBlogs trigger={trigger}/>
            </PortalHeader>

        </>
    )
};

export default Adminblog;