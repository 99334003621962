import React from 'react';
import Header from "../components/header/Header";
import TermsConditions from "../components/termsPrivacy/Terms";
import Footer from "../components/footer/Footer";
import SEO from "../common/SEO";
import aboutBanner from "../assets/Images/Terms&Condition.webp";
import InnerPageBanner from '../components/shared/InnerPageBanner';

const Terms = () => {
   
    return (
        <>
            <SEO title='Terms & Conditions - My Ev Point | EV Charging Stations' description='The Terms and Conditions at My Ev Point, your go-to destination for reliable EV charging stations. Learn more about our services and policies.' />
            <Header />
            <InnerPageBanner title='Terms & Conditions' maxWidth='472px' innerPageBanner={aboutBanner} />
            <TermsConditions />
            <Footer />
        </>
    )
}

export default Terms;