import React from "react";
import { createContext, useState } from "react";

export const UIContext = createContext({
    searchTerm: "",
    setSearchTerm: () => {},
    searchedData: [],
    setSearchedData: () => {}
});

export default function ContextProvider({children}) {
    const [ searchTerm, setSearchTerm ] = useState("");
    const [ searchedData, setSearchedData ] = useState([]);
    const data = {
        searchTerm,
        setSearchTerm,
        searchedData,
        setSearchedData
    }
    return(
        <UIContext.Provider value={data}>
            {children}
        </UIContext.Provider>
    )
}
