import React,{useEffect} from 'react'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import ContactInfo from '../components/contact_info/ContactInfo';
import InnerPageBanner from '../components/shared/InnerPageBanner';
import aboutBanner from "../assets/Images/AboutUs.webp";
import SEO from '../common/SEO';
import { Link,useNavigate} from 'react-router-dom';

const Thankyou = () => {
    const navigate = useNavigate();

    useEffect(() => {
      setTimeout(() => {
        navigate("/")
      },2000)
    },[])

    return (
        <>
            <SEO title="Thank You - My EV Point is a leading EV charging company" description='My EV Point - the premier choice for EV charging. Reliable, fast, and convenient stations to power your journey. Learn more about us today' />
            <Header />
            <InnerPageBanner title='Thank You' maxWidth='472px' innerPageBanner={aboutBanner} />
            <section className='bg-[#fff] md:py-[80px] max-md:py-[50px]'>
                <div className='container'>
                    <div>
                        <div className="flex flex-col items-center space-y-2">
                            <svg xmlns="http://www.w3.org/2000/svg" className="text-green-600 w-28 h-28" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor" stroke-width="1">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <h2 className="text-center font-bold">Thank You !</h2>
                            <p className='text-center'>Thank you for your interest! Check your email for a link to the guide.</p>
                            <div className='!mt-[30px]'>
                                <Link className='md:text-[18px] text-[16px] text-white bg-[#0B7132] hover:bg-[#000] text-[#fff] rounded-l-[10px] rounded-r-[10px] lg:px-[40px] md:px-[30px] px-[25px] py-[16px] inline-block' to='/'>Return to Home</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ContactInfo />
            <Footer />
        </>
    )
}

export default Thankyou
