import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { windowScroll } from '../helpers/ScrollToTop';
import servicesData from "../data/cities.json"



const Sitemap = () => {
    const [allBlogs, setAllBlogs] = useState([])


    const fetchBlogs = async () => {
        const response = await axios.get(`${process.env.REACT_APP_URL}/user/getBlogs`);
        const filteredData = response.data.data.filter(item => item.isActive === true);
        const sortedBlogs = filteredData.sort((a, b) => new Date(b.publishedAt) - new Date(a.publishedAt));

        setAllBlogs(sortedBlogs)
    }


    useEffect(() => {
        fetchBlogs()
    }, [])

    return (
        <>
            {/* <SEO title='Sitemap'/> */}
            {/* <Layout> */}
            <div className='container md:py-[70px] max-md:py-[50px] max-sm:overflow-hidden max-sm:overflow-x-auto'>
                <section>
                    <ul className='list-none m-0 p-0'>
                        <li className='relative pl-[20px] pb-[5px]'>
                            <Link onClick={windowScroll} to="/" className='inline-block py-[5px]'>Home</Link>
                            <ul className='list-none m-0 p-0'>
                                {navLinks.map((item, i) => (
                                    <li key={i} className='relative pl-[20px] pb-[5px] last:pb-0 before:content-[""] before:absolute before:top-0 before:left-[2px] before:h-full before:border-l-[1px] before:border-dotted before:border-[#212121] after:content-[""] after:absolute after:top-[15px] after:left-[3px] after:border-t-[1px] after:border-dotted after:border-[#212121] after:w-[12px]'>
                                        <Link onClick={windowScroll} to={item.link} className='inline-block py-[5px]'>{item.title}</Link>
                                        {item.title === "Blogs" &&
                                            <>
                                                <ul className='list-none m-0 p-0'>
                                                    {allBlogs?.map((item, i) => (
                                                        <li key={i} className='relative pl-[20px] pb-[5px] last:pb-0 before:content-[""] before:absolute before:top-0 before:left-[2px] before:h-full before:border-l-[1px] before:border-dotted before:border-[#212121] after:content-[""] after:absolute after:top-[15px] after:left-[3px] after:border-t-[1px] after:border-dotted after:border-[#212121] after:w-[12px]'>
                                                            <Link onClick={windowScroll} className='inline-block py-[5px]' to={`/blogs/${item?.handle}`}>{item?.title}</Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </>
                                        }
                                        {item?.title === "States" &&
                                            <>
                                                <ul className='list-none m-0 p-0'>
                                                    {servicesData?.map((item, i) => (
                                                        <li key={i} className='relative pl-[20px] pb-[5px] last:pb-0 before:content-[""] before:absolute before:top-0 before:left-[2px] before:h-full before:border-l-[1px] before:border-dotted before:border-[#212121] after:content-[""] after:absolute after:top-[15px] after:left-[3px] after:border-t-[1px] after:border-dotted after:border-[#212121] after:w-[12px]'>
                                                            <Link onClick={windowScroll} className='inline-block py-[5px]' to={`/${item?.types}`}>{item?.title}</Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </>
                                        }
                                     
                                        <ul className='list-none m-0 p-0'>
                                            {item.subLinks?.map((item, i) => (
                                                <li key={i} className='relative pl-[20px] pb-[5px] last:pb-0 before:content-[""] before:absolute before:top-0 before:left-[2px] before:h-full before:border-l-[1px] before:border-dotted before:border-[#212121] after:content-[""] after:absolute after:top-[15px] after:left-[3px] after:border-t-[1px] after:border-dotted after:border-[#212121] after:w-[12px]'>
                                                    <Link onClick={windowScroll} className='inline-block py-[5px]' to={item.link}>{item.title}</Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </li>
                                ))}
                            </ul>
                        </li>
                    </ul>
                </section>
            </div>
            {/* </Layout> */}
        </>
    )
}

const navLinks = [
    {
        title: "About Us",
        link: "/about-us"

    },
    {
        title: "Products",
        link: "/products"
    },
    {
        title: "States",
        link: "/states",

    },
    {
        title: "Our Services",
        link: "/services",
        subLinks: [
            {
                title: "AC Charger Service",
                link: "/services/ac-charging-station"
            },
            {
                title: "DC Charger Service",
                link: "/services/dc-charging-station"
            },



        ]
    },
    {
        title: "How to use",
        link: "/vehicle-charging-guide"
    },
    {
        title: "Blogs",
        link: "/blogs",

    },
    {
        title: "Gallery",
        link: "/gallery",
        subLinks: [
            {
                title: "Images",
                link: "/gallery/images"
            },
            {
                title: "Videos",
                link: "/gallery/videos"
            },



        ]
    },
    {
        title: "Contact Us",
        link: "/contact-us"
    },
    {
        title: "Get App",
        link: "/ev-charging-app"
    },
    {
        title: "Faqs",
        link: "/faqs"
    },
    {
        title: "Privacy Policy",
        link: "/privacy-policy"
    },
    {
        title: "Terms & Conditions",
        link: "/terms-and-conditions"
    },
    {
        title: "Refund Policy",
        link: "/refund-policy"
    },
    {
        title: "Cancellation Policy",
        link: "/cancellation-policy"
    }
];

export default Sitemap;