import React from "react";
import ImageIcons from "../../common/ImageIcons";
import aboutChargingBg from "../../assets/Images/about_charging_bg.svg";
import { Link } from 'react-router-dom'
import { windowScroll } from '../../helpers/ScrollToTop';





const Charges = () => {
  const features = [
    {
      id: "01",
      text: "Charging station for home",
    },
    {
      id: "02",
      text: "Charge point setting",
    },
    {
      id: "03",
      text: "Free Support",
    },
    {
      id: "04",
      text: "1 year hardware warranty",
    },
  ];

  const features1 = [
    {
      id: "01",
      text: "Public charging station",
    },
    {
      id: "02",
      text: "Charge point setting",
    },
    {
      id: "03",
      text: "Free Support",
    },
    {
      id: "04",
      text: "1 year hardware warranty",
    },
  ];

  const FeatureList = ({ item }) => (
    <div className="my-[5px] flex flex-col ">
      <div className=" flex flex-row items-center justify-start">
        <img src={ImageIcons.Polygon} alt="Polygon" />
        <p className="ml-[10px] text-[#6E6E6E]">{item.text}</p>
      </div>
    </div>
  );
  const FeatureList1 = ({ item }) => (
    <div className="my-[5px] flex flex-col ">
      <div className=" flex flex-row items-center justify-start">
        <img src={ImageIcons.Polygon} alt="Polygon" />
        <p className="ml-[10px] text-[#fff]">{item.text}</p>
      </div>
    </div>
  );

  return (
    <>
      <section className="md:py-[90px] py-[50px] bg-[#F1F8E8] relative">
        <div className="container">
          <div className="flex lg:flex-row flex-col xl:gap-[30px] lg:gap-[20px]">
            <div className="lg:w-[39%] md:w-[100%] lg:mb-[0]">
              <div className="flex flex-row place-items-center">
                <h4 className="font-medium relative text-[#0B7132] font-medium mb-[10px] green-line-heading">ABOUT ECHARGE</h4>
              </div>
              <h2 className="font-semibold text-[#000] mb-[22px]"> Our Services on The Road</h2>
              <p className="max-w-xl text-xl font-normal text-[#000] mt-6"> An EV charging station is a device that provides electric energy for recharging electric vehicles, enabling the transfer of electrical energy from the grid to the vehicle's battery pack.</p>
              <div>
                <img src={ImageIcons.filling_machine} alt="electric vehicle charger" className="absolute left-0 z-1 bottom-0 max-w-[350px] max-lg:hidden" />
                <img className="absolute left-0 right-[230px] bottom-10 m-auto z-1 max-w-[350px] max-lg:hidden" src={aboutChargingBg} alt="about_US" />
              </div>
            </div>
            <div className="lg:mt-0 mt-[40px] lg:w-[61%] md:w-[100%] grid grid-cols-1 sm:grid-cols-2 gap-y-[40px] gap-x-[20px] xl:gap-x-[26px] lg:gap-x-[15px]">
              <div className="relative px-[15px] pt-[35px] pb-[95px] home_charging-bg after:bg-[#fff]">
                <div className="relative z-20">
                  <h4 className="text-[#0B7132] mb-[15px]">HOME CHARGING </h4>
                  {/* <h2 className="text-black mb-[15px] xl:text-[44px] lg:text-[42px]">
                    <span className="font-sans font-normal">₹</span> 1,18,000/-
                  </h2> */}
                  <p className="text-[#676767] text-[16px]">
                    Explore our professional installation services to ensure a seamless and safe home charging setup.
                  </p>
                  <div className="p-[20px] my-[30px] border border-solid border-[#E5E5E5] rounded-3xl">
                    {features.map((item) => (
                      <FeatureList key={item.id} item={item} />
                    ))}
                  </div>
                  <Link to="/contact-us" onClick={windowScroll}>
                    <button className="duration-[400ms,700ms] px-3 min-w-[160px] md:px-3 py-3 md:py-4 bg-[#0B7132]  hover:bg-[#ffffff] border-2 border-[#0B7132] hover:text-black hover:border-2 hover:border-[#0B7132] text-white rounded-full">
                      Contact Us
                    </button>
                  </Link>
                </div>
              </div>
              <div className="relative px-[15px] pt-[35px] pb-[95px] home_charging-bg after:bg-[#000]" >
                <div className="relative z-20">
                  <h4 className="text-white mb-[15px]">PUBLIC CHARGING</h4>
                  {/* <h2 className="text-white mb-[15px] xl:text-[44px] lg:text-[42px]">
                    <span className="font-sans font-normal">₹</span> 2,10,000/-
                  </h2> */}
                  <p className="text-white text-[16px]">
                    Find public charging stations near popular destinations, highways, and key locations.
                  </p>
                  <div className="p-[20px] my-[30px] border border-solid border-[#E5E5E5] rounded-3xl">
                    {features1.map((item) => (
                      <FeatureList1 key={item.id} item={item} />
                    ))}
                  </div>
                  <Link to="/contact-us" onClick={windowScroll}>
                    <button className="duration-[400ms,700ms] px-3 min-w-[160px] md:px-3 py-3 md:py-4 bg-[#0B7132] bg-[#0B7132] hover:bg-[#ffffff] border-2 border-[#0B7132] hover:text-black hover:border-2 hover:border-[#0B7132] text-white rounded-full">
                      Contact Us
                    </button>
                  </Link>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Charges;