import React from 'react'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import Contact from '../components/contact/Contact';
import InnerPageBanner from '../components/shared/InnerPageBanner';
import aboutBanner from "../assets/Images/Contactus.webp";
import SEO from '../common/SEO';

const ContactUs = () => {
    return (
        <>
        <SEO title='Contact Us |  With MyEvPoint' description='MyEvPoint for top-notch EV solutions! Contact us today for expert advice, support, and all your EV needs. Click now for excellent service!'/>
            <Header />
            <InnerPageBanner
                title='Contact Us'
                innerPageBanner={aboutBanner}
            />
            {/* <Banner/> */}

            <Contact />
            {/* <ContactInfo /> */}
            <Footer />
        </>
    )
}

export default ContactUs;