import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { windowScroll } from '../helpers/ScrollToTop';
import axios from 'axios';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ImageIcons from '../common/ImageIcons';

const Blogsection = () => {

  const [allBlogs, setAllBlogs] = useState([])

  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const removeHTMLTags = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  };



  const fetchBlogs = async () => {
    const response = await axios.get(`${process.env.REACT_APP_URL}/user/getBlogs`);

    const filteredData = response.data.data.filter(item => item.isActive === true);


    const sortedBlogs = filteredData.sort((a, b) => new Date(b.publishedAt) - new Date(a.publishedAt));
    const latest3Blogs = sortedBlogs.slice(0, 3);

    setAllBlogs(latest3Blogs)
  }


  useEffect(() => {
    fetchBlogs()
  }, [])

  return (
    <>
      {/* Our Blog Section */}
      <div className="blogs-section pb-20 pt-10 relative">
        <div className="container mx-auto px-2">
          <h4 className="text-[#0B7132] font-medium uppercase	mb-[15px] text-center">Our Blogs</h4>
          <h2 className="text-center md:pb-12 max-md:pb-8 text-[#000]">Recent Articles and News</h2>
          <div className="flex max-md:flex-col lg:gap-10 max-lg:gap-5">

            {allBlogs.map((item) => {
              const dateObject = new Date(item?.publishedAt);
              const formattedDate = dateObject.toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric",
              });

              return (
                <div className="md:w-1/3 relative max-md:mb-5" key={item.id}>
                  <div className="blog-list-info bg-[#fff] rounded p-4 shadow-[0px_0px_25px_0px_rgba(0,0,0,0.15)]">
                  <Link to={`/blogs/${item?.handle}`} onClick={windowScroll}>
                    <div className={`blog-img-info mb-3 ${imageLoaded ? '' : 'bg-gray-300 animate-pulse'}`} >
                      <LazyLoadImage className="w-full rounded-lg cursor-pointer object-center object-cover md:h-[300px]" src={item?.image} alt={item.alttag} title={item.imagetitle}
                        effect="blur"
                        onLoad={handleImageLoad}
                        placeholderSrc={ImageIcons.loading} 
                        />
                    </div>
                    </Link>

                    <div className="blog-content-info lg:p-4">
                      <Link className='hidden md:block'  to={`/blogs/${item?.handle}`} onClick={windowScroll}>
                        <h4 className="mb-3 cursor-pointer hover:text-[#0B7132]"
                          style={{
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            WebkitLineClamp: 2
                          }}
                        >{item?.title}</h4>
                      </Link>
                      <Link className=' md:hidden'  to={`/blogs/${item?.handle}`} onClick={windowScroll}>
                        <h4 className="mb-3 cursor-pointer hover:text-[#0B7132]"
                          style={{
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            WebkitLineClamp: 2
                          }}
                        >{item?.title}</h4>
                      </Link>
                      {/* <div className="blog-autor-info mb-3">
                        <p className="text-[#a7a7a7]">{item?.author} | {formattedDate}</p>
                      </div> */}
                      <p style={{
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        WebkitLineClamp: 2
                      }}>
                        {removeHTMLTags(item?.description)}
                      </p>
                      <Link to={`/blogs/${item?.handle}`} onClick={windowScroll}>
                        <span className='text-[#0B7132]'>Read More</span>
                      </Link>
                      <div className="blog-autor-info mt-3">
                        <p className="text-[#000] font-semibold">Author:<span className='font-normal'> {item?.author}</span></p>
                        <p className="text-[#000] font-semibold">Published At:<span className='font-normal'> {formattedDate}</span></p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

          </div>
        </div>
      </div>

    </>
  );
};
export default Blogsection;
