import React from 'react'

const ChargingGuide = () => {
    return (
        <>
            <div className='w-full py-20'>
                <div className='container mx-auto px-2'>
                    <h2 className='text-center'>How to Charge Your Electric Car with My EV Point</h2>
                    <div className='lg:mt-[50px] max-lg:mt-[40px]'>
                        <div className="container p-0">
                            <div className="flex flex-col md:grid grid-cols-9 mx-auto">
                                <div className="flex md:contents">
                                    <div className="bg-[#F1F8E8] col-start-1 col-end-5 p-4 rounded-xl my-4 ml-auto shadow-md">
                                        <p className="text-[#000] font-medium mb-0">Turn on the main switch.</p>
                                    </div>
                                    <div className="col-start-5 col-end-6 md:mx-auto relative ml-[20px]">
                                        <div className="h-full w-[35px] flex items-center justify-center">
                                            <div className="h-full w-1 bg-[#0B7132] pointer-events-none"></div>
                                        </div>
                                        <div className="w-[35px] h-[35px] absolute top-1/2 -mt-[18px] rounded-full bg-[#0B7132] shadow"><span className='text-[#fff] text-[18px] flex justify-center items-center h-[35px]'>1</span></div>
                                    </div>
                                </div>
                                <div className="flex md:contents">
                                    <div className="col-start-5 col-end-6 mr-[20px] md:mx-auto relative">
                                        <div className="h-full w-[35px] flex items-center justify-center">
                                            <div className="h-full w-1 bg-[#0B7132] pointer-events-none"></div>
                                        </div>
                                        <div className="w-[35px] h-[35px] absolute top-1/2 -mt-[18px] rounded-full bg-[#0B7132] shadow"><span className='text-[#fff] text-[18px] flex justify-center items-center h-[35px]'>2</span></div>
                                    </div>
                                    <div className="bg-[#F1F8E8] col-start-6 col-end-10 p-4 rounded-xl my-4 mr-auto shadow-md">
                                        <p className="text-[#000] font-medium mb-0">Now, you will see a blue LED on the machine.</p>
                                    </div>
                                </div>
                                <div className="flex md:contents">
                                    <div className="bg-[#F1F8E8] col-start-1 col-end-5 p-4 rounded-xl my-4 ml-auto shadow-md">
                                        <p className="text-[#000] font-medium mb-0">Thereafter, connect the nozzle to your car.</p>
                                    </div>
                                    <div className="col-start-5 col-end-6 md:mx-auto relative ml-[20px]">
                                        <div className="h-full w-[35px] flex items-center justify-center">
                                            <div className="h-full w-1 bg-[#0B7132] pointer-events-none"></div>
                                        </div>
                                        <div className="w-[35px] h-[35px] absolute top-1/2 -mt-[18px] rounded-full bg-[#0B7132] shadow"><span className='text-[#fff] text-[18px] flex justify-center items-center h-[35px]'>3</span></div>
                                    </div>
                                </div>
                                <div className="flex md:contents">
                                    <div className="col-start-5 col-end-6 mr-[20px] md:mx-auto relative">
                                        <div className="h-full w-[35px] flex items-center justify-center">
                                            <div className="h-full w-1 bg-[#0B7132] pointer-events-none"></div>
                                        </div>
                                        <div className="w-[35px] h-[35px] absolute top-1/2 -mt-[18px] rounded-full bg-[#0B7132] shadow"><span className='text-[#fff] text-[18px] flex justify-center items-center h-[35px]'>4</span></div>
                                    </div>
                                    <div className="bg-[#F1F8E8] col-start-6 col-end-10 p-4 rounded-xl my-4 mr-auto shadow-md">
                                        <p className="text-[#000] font-medium mb-0">Following this, the blue LED will turn into Green.</p>
                                    </div>
                                </div>
                                <div className="flex md:contents">
                                    <div className="bg-[#F1F8E8] col-start-1 col-end-5 p-4 rounded-xl my-4 ml-auto shadow-md">
                                        <p className="text-[#000] font-medium mb-0">Touch the screen and here you will see two options that are OTP or APP for charging. Select one.</p>
                                    </div>
                                    <div className="col-start-5 col-end-6 md:mx-auto relative ml-[20px]">
                                        <div className="h-full w-[35px] flex items-center justify-center">
                                            <div className="h-full w-1 bg-[#0B7132] pointer-events-none"></div>
                                        </div>
                                        <div className="w-[35px] h-[35px] absolute top-1/2 -mt-[18px] rounded-full bg-[#0B7132] shadow"><span className='text-[#fff] text-[18px] flex justify-center items-center h-[35px]'>5</span></div>
                                    </div>
                                </div>
                                <div className="flex md:contents">
                                    <div className="col-start-5 col-end-6 mr-[20px] md:mx-auto relative">
                                        <div className="h-full w-[35px] flex items-center justify-center">
                                            <div className="h-full w-1 bg-[#0B7132] pointer-events-none"></div>
                                        </div>
                                        <div className="w-[35px] h-[35px] absolute top-1/2 -mt-[18px] rounded-full bg-[#0B7132] shadow"><span className='text-[#fff] text-[18px] flex justify-center items-center h-[35px]'>6</span></div>
                                    </div>
                                    <div className="bg-[#F1F8E8] col-start-6 col-end-10 p-4 rounded-xl my-4 mr-auto shadow-md">
                                        <p className="text-[#000] font-medium mb-0">If you choose OTP, which will be provided to you by the company, you have to enter OTP.</p>
                                    </div>
                                </div>
                                <div className="flex md:contents">
                                    <div className="bg-[#F1F8E8] col-start-1 col-end-5 p-4 rounded-xl my-4 ml-auto shadow-md">
                                        <p className="text-[#000] font-medium mb-0">After this, the machine will go into 20-30 seconds of standby time.</p>
                                    </div>
                                    <div className="col-start-5 col-end-6 md:mx-auto relative ml-[20px]">
                                        <div className="h-full w-[35px] flex items-center justify-center">
                                            <div className="h-full w-1 bg-[#0B7132] pointer-events-none"></div>
                                        </div>
                                        <div className="w-[35px] h-[35px] absolute top-1/2 -mt-[18px] rounded-full bg-[#0B7132] shadow"><span className='text-[#fff] text-[18px] flex justify-center items-center h-[35px]'>7</span></div>
                                    </div>
                                </div>
                                <div className="flex md:contents">
                                    <div className="col-start-5 col-end-6 mr-[20px] md:mx-auto relative">
                                        <div className="h-full w-[35px] flex items-center justify-center">
                                            <div className="h-full w-1 bg-[#0B7132] pointer-events-none"></div>
                                        </div>
                                        <div className="w-[35px] h-[35px] absolute top-1/2 -mt-[18px] rounded-full bg-[#0B7132] shadow"><span className='text-[#fff] text-[18px] flex justify-center items-center h-[35px]'>8</span></div>
                                    </div>
                                    <div className="bg-[#F1F8E8] col-start-6 col-end-10 p-4 rounded-xl my-4 mr-auto shadow-md">
                                        <p className="text-[#000] font-medium mb-0">Eventually, charging will be initiated and you will see all the details regarding charging like charging status and unit consumption on your screen.</p>
                                    </div>
                                </div>
                                <div className="flex md:contents">
                                    <div className="bg-[#F1F8E8] col-start-1 col-end-5 p-4 rounded-xl my-4 ml-auto shadow-md">
                                        <p className="text-[#000] font-medium mb-0">When the car is fully charged, the machine will automatically stop charging.</p>
                                    </div>
                                    <div className="col-start-5 col-end-6 md:mx-auto relative ml-[20px]">
                                        <div className="h-full w-[35px] flex items-center justify-center">
                                            <div className="h-full w-1 bg-[#0B7132] pointer-events-none"></div>
                                        </div>
                                        <div className="w-[35px] h-[35px] absolute top-1/2 -mt-[18px] rounded-full bg-[#0B7132] shadow"><span className='text-[#fff] text-[18px] flex justify-center items-center h-[35px]'>9</span></div>
                                    </div>
                                </div>
                                <div className="flex md:contents">
                                    <div className="col-start-5 col-end-6 mr-[20px] md:mx-auto relative">
                                        <div className="h-full w-[35px] flex items-center justify-center">
                                            <div className="h-full w-1 bg-[#0B7132] pointer-events-none"></div>
                                        </div>
                                        <div className="w-[35px] h-[35px] absolute top-1/2 -mt-[18px] rounded-full bg-[#0B7132] shadow"><span className='text-[#fff] text-[18px] flex justify-center items-center h-[35px]'>10</span></div>
                                    </div>
                                    <div className="bg-[#F1F8E8] col-start-6 col-end-10 p-4 rounded-xl my-4 mr-auto shadow-md">
                                        <p className="text-[#000] font-medium mb-0">If you choose the option of App, you will have to put some amount into your application wallet.</p>
                                    </div>
                                </div>
                                <div className="flex md:contents">
                                    <div className="bg-[#F1F8E8] col-start-1 col-end-5 p-4 rounded-xl my-4 ml-auto shadow-md">
                                        <p className="text-[#000] font-medium mb-0">Following this, you will have to scan the QR code on the machine or select the device ID.</p>
                                    </div>
                                    <div className="col-start-5 col-end-6 md:mx-auto relative ml-[20px]">
                                        <div className="h-full w-[35px] flex items-center justify-center">
                                            <div className="h-full w-1 bg-[#0B7132] pointer-events-none"></div>
                                        </div>
                                        <div className="w-[35px] h-[35px] absolute top-1/2 -mt-[18px] rounded-full bg-[#0B7132] shadow"><span className='text-[#fff] text-[18px] flex justify-center items-center h-[35px]'>11</span></div>
                                    </div>
                                </div>
                                <div className="flex md:contents">
                                    <div className="col-start-5 col-end-6 mr-[20px] md:mx-auto relative">
                                        <div className="h-full w-[35px] flex items-center justify-center">
                                            <div className="h-full w-1 bg-[#0B7132] pointer-events-none"></div>
                                        </div>
                                        <div className="w-[35px] h-[35px] absolute top-1/2 -mt-[18px] rounded-full bg-[#0B7132] shadow"><span className='text-[#fff] text-[18px] flex justify-center items-center h-[35px]'>12</span></div>
                                    </div>
                                    <div className="bg-[#F1F8E8] col-start-6 col-end-10 p-4 rounded-xl my-4 mr-auto shadow-md">
                                        <p className="text-[#000] font-medium mb-[10px]">You will see three options on the screen for initiating charging that are-</p>
                                        <ul className='text-[14px] mb-[10px]'>
                                            <li><b>Hours-</b> For how much time you want to charge your EV (like on an hourly basis)</li>
                                            <li><b>Amount-</b> total amount for which you want to charge your EV</li>
                                            <li><b>Units-</b> how many units you want to get consumed.</li>
                                        </ul>
                                        <p className="text-[14px] text-[#000] font-medium mb-0">Select one out of them, you will see the details on your screen according to the option chosen.</p>
                                    </div>
                                </div>
                                <div className="flex md:contents">
                                    <div className="bg-[#F1F8E8] col-start-1 col-end-5 p-4 rounded-xl my-4 ml-auto shadow-md">
                                        <p className="text-[#000] font-medium mb-0">Now, the charging will be initiated.</p>
                                    </div>
                                    <div className="col-start-5 col-end-6 md:mx-auto relative ml-[20px]">
                                        <div className="h-full w-[35px] flex items-center justify-center">
                                            <div className="h-full w-1 bg-[#0B7132] pointer-events-none"></div>
                                        </div>
                                        <div className="w-[35px] h-[35px] absolute top-1/2 -mt-[18px] rounded-full bg-[#0B7132] shadow"><span className='text-[#fff] text-[18px] flex justify-center items-center h-[35px]'>13</span></div>
                                    </div>
                                </div>
                                <div className="flex md:contents">
                                    <div className="col-start-5 col-end-6 mr-[20px] md:mx-auto relative">
                                        <div className="h-full w-[35px] flex items-center justify-center">
                                            <div className="h-full w-1 bg-[#0B7132] pointer-events-none"></div>
                                        </div>
                                        <div className="w-[35px] h-[35px] absolute top-1/2 -mt-[18px] rounded-full bg-[#0B7132] shadow"><span className='text-[#fff] text-[18px] flex justify-center items-center h-[35px]'>14</span></div>
                                    </div>
                                    <div className="bg-[#F1F8E8] col-start-6 col-end-10 p-4 rounded-xl my-4 mr-auto shadow-md">
                                        <p className="text-[#000] font-medium mb-0">The amount will be deducted at the end.</p>
                                    </div>
                                </div>
                                <div className="flex md:contents">
                                    <div className="bg-[#F1F8E8] col-start-1 col-end-5 p-4 rounded-xl my-4 ml-auto shadow-md">
                                        <p className="text-[#000] font-medium mb-0">If you want to turn off charging, you will have to turn off your charging from the display screen.</p>
                                    </div>
                                    <div className="col-start-5 col-end-6 md:mx-auto relative ml-[20px]">
                                        <div className="h-full w-[35px] flex items-center justify-center">
                                            <div className="h-full w-1 bg-[#0B7132] pointer-events-none"></div>
                                        </div>
                                        <div className="w-[35px] h-[35px] absolute top-1/2 -mt-[18px] rounded-full bg-[#0B7132] shadow"><span className='text-[#fff] text-[18px] flex justify-center items-center h-[35px]'>15</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mt-[40px] text-center text-[#e50914] '>
                        <p><b>Note- You would not be able to remove the nozzle until you stop charging from the machine.</b></p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChargingGuide
