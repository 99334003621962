import React, { useEffect, useState } from 'react'
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import SEO from "../common/SEO";
import InnerPageBanner from '../components/shared/InnerPageBanner';
import Delhi from "../components/cities/Delhi";
import servicesData from "../data/cities.json";
import { useParams } from "react-router-dom"
import ContactInfo from '../components/contact_info/ContactInfo';


const Delhis = () => {
    const { types } = useParams();

    const [serviceDetails, setServiceDetails] = useState(null);



    useEffect(() => {
        const selectedService = servicesData.find(service => service.types === types);
        setServiceDetails(selectedService);
    }, [types])
    return (
        <>
            <SEO title={serviceDetails?.metaTitle} description={serviceDetails?.metaDescription} keywords={serviceDetails?.keywords}  />
            <Header />
            <InnerPageBanner
                title={serviceDetails?.cityname}
                innerPageBanner={serviceDetails?.bannerimage}
            />
            <Delhi/>
            <ContactInfo/>
            <Footer />
        </>
    )
}
export default Delhis;