import React, { useState, useEffect } from 'react';
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import axios from 'axios';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ImageIcons from '../../common/ImageIcons';
import { Link } from 'react-router-dom';
import { windowScroll } from '../../helpers/ScrollToTop';


const GalleryPage = () => {
  const [openImageModal, setOpenImageModal] = useState(false);
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [gallery, setGallery] = useState({ images: [], videos: [] });
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);

  const gallery1 = [
    {
      video: "https://www.youtube.com/embed/QraLIsumt0U?si=6J2_jt59XStVQLFa",
    },
    {
      video: "https://www.youtube.com/embed/a0mDIPbZtNo?si=g9-SLkEi8F7jb_2i",
    },


  ];

  useEffect(() => {
    const fetchGallery = async () => {
      try {
        const imageResponse = await axios.get(`${process.env.REACT_APP_URL}/user/getGallery`);
        const videoResponse = await axios.get(`${process.env.REACT_APP_URL}/user/getVideo`);
        const filteredImageData = imageResponse.data.data.filter(item => item.isActive === true);
        const sortedImageData = filteredImageData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        setGallery({
          images: sortedImageData,
          videos: videoResponse.data.data,
        });
      } catch (error) {
      }
    };

    fetchGallery();
  }, []);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const openImageLightbox = (index) => {
    setCurrentImageIndex(index);
    setOpenImageModal(true);
  };

  const openVideoLightbox = (index) => {
    setCurrentVideoIndex(index);
    setOpenVideoModal(true);
  };

  const handleCloseImageModal = () => {
    setOpenImageModal(false);
  };

  const handleCloseVideoModal = () => {
    setOpenVideoModal(false);
  };

  const gotoNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex + 1 < gallery.images.length ? prevIndex + 1 : 0
    );
  };

  const gotoPrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : gallery.images.length - 1
    );
  };

  // const gotoNextVideo = () => {
  //   setCurrentVideoIndex((prevIndex) =>
  //     prevIndex + 1 < gallery1.length ? prevIndex + 1 : 0
  //   );
  // };

  // const gotoPrevVideo = () => {
  //   setCurrentVideoIndex((prevIndex) =>
  //     prevIndex > 0 ? prevIndex - 1 : gallery1.length - 1
  //   );
  // };
  return (
    <>
      <div className="gallery-section md:pt-[80px] max-md:pt-[50px] relative">
        <div className="container mx-auto px-2">
          <div className='mb-[24px]'>
            <h2>Images</h2>
            <p> <Link to='/gallery/images' className='text-[#0B7132] font-semibold' onClick={windowScroll}>View All</Link></p>
          </div>
          <div className="flex gap-2 ">
            <ul className="grid max-md:grid-cols-2 max-lg:grid-cols-3 lg:grid-cols-5 md:gap-[20px] max-md:gap-[15px] pb-2">
              {gallery.images.slice(0, 5).map((item, index) => (
                <li key={index} className={`${imageLoaded ? '' : 'bg-gray-300 animate-pulse'}`}>
                  <LazyLoadImage
                    className="cursor-pointer object-center object-cover w-[250px] sm:h-[250px] max-sm:h-[160px]"
                    effect="blur"
                    onLoad={handleImageLoad}
                    placeholderSrc={ImageIcons.loading}
                    src={item.image}
                    alt={item.alt}
                    onClick={() => openImageLightbox(index)}
                  />
                </li>
              ))}

            </ul>
          </div>
          {/* <div className=' text-center mt-12  '>
            <Link to='/gallery/images'>
              <button className="text-white bg-[#0B7132] hover:bg-black rounded-full md:px-10 max-md:px-8 md:py-4 max-md:py-3 inline-flex items-center duration-[400ms,700ms]">
                View All Images
              </button>
            </Link>
          </div> */}
        </div>
      </div>

      <Modal
        open={openImageModal}
        onClose={handleCloseImageModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ backdropFilter: "blur(7px)" }}
      >
        <div>
          <div className="absolute md:left-5 max-md:left-2 top-1/2">
            <ChevronLeftIcon className='text-[#fff] hover:text-[#0B7132] max-md:!w-[35px]' onClick={gotoPrevImage} sx={{ fontSize: 70, cursor: 'pointer' }} />
          </div>
          <Box sx={style} className="focus:outline-none focus-visible:outline-none max-md:w-[75%]">
            <div className='flex flex-row items-center bg-[#fff] p-[8px]'>
              <img className='w-full md:h-full md:object-contain md:max-h-[90vh]'
                src={gallery.images[currentImageIndex]?.image}
                alt={`gallery-${currentImageIndex}`}
              />
            </div>
          </Box>
          <div className="absolute md:right-5 max-md:right-2 top-1/2">
            <ChevronRightIcon className='text-[#fff] hover:text-[#0B7132] max-md:!w-[35px]' onClick={gotoNextImage} sx={{ fontSize: 70, cursor: 'pointer' }} />
          </div>
        </div>
      </Modal>

      <div className="gallery-section md:pb-[80px] max-md:pt-[30px] md:pt-[50px] max-md:pb-[50px] relative">
        <div className="container mx-auto px-2">
          <div className='mb-[24px]'>
            <h2>Videos</h2>
            <p><Link to='/gallery/videos' className='text-[#0B7132] font-semibold' onClick={windowScroll}>View All</Link></p>
          </div>
          <div className="grid max-md:grid-cols-1 max-lg:grid-cols-2 lg:grid-cols-3 md:gap-[30px] max-md:gap-[15px] pb-2 mx-auto">
            {gallery1 && gallery1.map((item, index) => (
              <div key={index} className="relative p-[28%] w-full overflow-hidden">
                <iframe
                  className="cursor-pointer absolute top-0 left-0 w-full h-full object-center object-cover"
                  src={item?.video}
                  title={`video-${index}`}
                  allowFullScreen
                  onClick={() => openVideoLightbox(index)}
                ></iframe>
              </div>
            ))}
          </div>
          {/* <div className=' text-center mt-12  '>
            <Link to='/gallery/videos'>
              <button className="text-white bg-[#0B7132] hover:bg-black rounded-full md:px-10 max-md:px-8 md:py-4 max-md:py-3 inline-flex items-center duration-[400ms,700ms]">
                View All Videos
              </button>
            </Link>
          </div> */}
        </div>
      </div>
      <Modal
        open={openVideoModal}
        onClose={handleCloseVideoModal}
        aria-labelledby="video-lightbox-title"
        aria-describedby="video-lightbox-description"
        style={{ backdropFilter: "blur(7px)" }}
      >
        <div>
          {gallery1.length > 0 && gallery1[currentVideoIndex] && (
            <iframe
              className="w-full h-full"
              src={gallery1[currentVideoIndex].video}
              title={`video-${currentVideoIndex}`}
              allowFullScreen
            ></iframe>
          )}
        </div>
      </Modal>

    </>
  );
};

export default GalleryPage;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
};