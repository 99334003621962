import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import data from "../../data/breadscrumbs.json"





const InnerPageBanner = ({ title = "", innerPageBanner = "" }) => {
    const location = useLocation();
    const { pathname } = location;
    const Tanu = pathname.split("/")
    const [service, setService] = useState("")
    


    useEffect(() => {
        const selectedService = data.find(service => service.path === pathname);
        setService(selectedService);
                        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    const capitalizeFirstLetter = (string) => {
        return string?.charAt(0).toUpperCase() + string?.slice(1);
    };

    return (
        <>
            <div className='relative'>
                <div className='relative w-full overflow-hidden py-[5%]'>
                    <img src={innerPageBanner} alt="Ev charging station" className='absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] w-full h-full object-center object-cover' />
                    <div className='relative w-full h-full flex flex-col justify-center items-center z-10 px-[15px] py-[15px]'>
                        <div className='heading_title relative z-10'>
                            {specificPaths.includes(service?.path) ? (
                                <h2 className="capitalize text-white text-center">{title}</h2>
                            ) : (
                                <h1 className="capitalize text-white text-center">{title}</h1>
                            )}
                        </div>

                        <div className='text-center mt-[10px]'>
                            <ul>
                                <li className='inline-block'>
                                    <Link className='text-white font-medium hover:underline' to='/'>Home</Link>
                                </li>
                                <span className='px-[10px] text-white font-medium inline-block'>/</span>
                                {service?.title2 ?
                                    <Link to={`/${service?.title1}`}><span className='inline-block text-white font-medium hover:underline'>{capitalizeFirstLetter(service?.title1)}</span></Link>
                                    :
                                    <span className='inline-block text-white font-medium '>{capitalizeFirstLetter(service?.title1)}</span>

                                }
                                {service?.title2 &&
                                    <div className='inline-block'>
                                        <span className='px-[10px] text-white font-medium inline-block'>/</span>
                                        <span className='inline-block text-white font-medium'> {service?.title2}</span>
                                    </div>
                                }

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const specificPaths = [
    "/cities/electric-vehicle-charging-station-delhi",
    "/cities/electric-vehicle-charging-station-punjab",
    "/cities/electric-vehicle-charging-station-haryana",
    "/cities/electric-vehicle-charging-station-himachal-pradesh",
    "/cities/electric-vehicle-charging-station-chandigarh",
    "/cities/electric-vehicle-charging-station-mumbai",
    "/cities/ev-charging-stations-up-uttar-pradesh"
];

export default InnerPageBanner;