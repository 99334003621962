import React from 'react'

const TermsConditions = () => {
    return (
        <>

            <section>
                <div className='container !max-w-[800px] mx-auto pb-20 pt-8 px-6 md:px-4 '>
                    {/* <div className="xl:mb-2 text-center">
                        <h1 className="text-center text-3xl text-black font-semibold md:text-4xl lg:text-5xl mb-10 md:mb-0">Terms and Conditions</h1>
                    </div> */}
                    <p className='text-[16px] mt-8'>{data?.description}</p>
                    <div className='py-6'>
                        {data.conditions.map((index, i) => (
                            <div key={i}>
                                <h2 className='mb-1 text-[28px]'>{index?.header}</h2>
                                <p className='text-[16px] para-design mb-5'
                                    dangerouslySetInnerHTML={{ __html: index?.desc }}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </section>

        </>
    )
}

const data = {
    description: "Kindly go through our Terms and Conditions before initiating your journey with us. It is necessary to understand them as they govern our relationship.",
    conditions: [
        {
            header: "Acceptance of Terms",
            desc: "It is to be noticed that you agree to abide by our terms and conditions when you access our services. Kindly make sure that you read and understand them thoroughly."
        },
        {
            header: "Service Description",
            desc: "We provide you with an array of services to ensure seamless operations. We offer charging station installation, charging network management. Besides this, management of user accounts is also offered by us. Users are provided with a secure payment system for smooth services. Instant customer support is our top-most priority."
        },
        {
            header: "User Responsibilities",
            desc: "The onus of managing and maintaining the confidentiality of the credentials of the accounts lies on the users. Users must ensure that their usage of myEV point is in line with rules and regulations."
        },
        {
            header: "Data Security and Privacy",
            desc: "As your security  is our priority, we are always devoted to providing high standard data protection. It is also suggested to users to use unique and secure passwords so that they can protect their personal confidential information."
        },
        {
            header: "Payments and Billing",
            desc: "On the subscription of myEV point’s premium services, users agree to pay the required specified amount timely. We safeguard the right to any changes in the prices in the future and users will surely be notified about the done changes."
        },
        {
            header: "Termination of services",
            desc: "The users who are found violating the terms and conditions of myEV point, their services can be suspended or terminated according to our policy. Users are also free to cancel their subscription, cancel or terminate the services at any time."
        },
        {
            header: "Intellectual Property",
            desc: "myEV point is committed to protect all the content and materials with intellectual property laws. Users  are provided with limited, non-exclusive licence, so that they can use the services for their own purpose."
        },
        {
            header: "Changes in Terms",
            desc: "We hold the right to upgrade our terms and conditions from time to time. But it is always taken care that users are notified about the changes done. If users continue to use the services, it assures their acceptance of revised Terms.<br>For smooth collaboration, it's imperative to understand our Terms and Conditions. If you still are sceptical about anything, feel free to contact our support team . We are always at your service."
        },

    ]
};

export default TermsConditions;