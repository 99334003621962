import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import SEO from "../common/SEO";
import Product from "../components/product/Product";
import InnerPageBanner from '../components/shared/InnerPageBanner';
import aboutBanner from "../assets/Images/ourproducts.webp";

const Products = () => {
    return (
        <>
            <SEO title='Charging Station Electric Vehicle - MyEVPoint Products ' description='MyEVPoint has a wide range of EV charging station products. From home chargers to commercial solutions, find the perfect charging solution for your EV.' />
            <Header />
            <InnerPageBanner title='Products' maxWidth='472px' innerPageBanner={aboutBanner} />
            <Product/>
            <Footer />
        </>
    )
}
export default Products;