import * as React from "react";
import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import axios from "axios";




const style = {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    width: '70%',
    maxHeight: '90%',
    overflowY: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    py: 2,
    px: 4
};




const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.action.hover,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.white,
    },
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));



const Contact = ({ trigger }) => {

    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const [detail, setDetail] = useState('')
    const handleClose = () => setOpen(false);



    const handleView = (item) => {
        setOpen(true);
        setDetail(item)
    };









    const fetchData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_URL}/user/getcontact`);
            setData(response?.data?.data);
        } catch (error) {
        }
    };
    useEffect(() => {
        fetchData();
    }, [trigger]);

    return (
        <>
            <div className="big-table">
                <div className="Categories-form relative z-9 bg-white mx-auto grid rounded-[10px] mt-5">
                    <h1 className="uppercase">Contacts</h1>
                    <TableContainer sx={{ maxWidth: 1000, minWidth: 1000 }}>
                        <Table aria-label="sticky table" className="w-150">
                            <TableHead >
                                <TableRow >
                                    <StyledTableCell sx={{ maxWidth: 50, minWidth: 50 }}>
                                        <strong>S.No.</strong>
                                    </StyledTableCell>

                                    <StyledTableCell sx={{ maxWidth: 150, minWidth: 150 }}>
                                        <strong>Full Name</strong>
                                    </StyledTableCell>
                                    
                                    <StyledTableCell sx={{ maxWidth: 150, minWidth: 150 }}>
                                        <strong>Phone Number</strong>
                                    </StyledTableCell>
                                    <StyledTableCell sx={{ maxWidth: 150, minWidth: 150 }}>
                                        <strong>Email</strong>
                                    </StyledTableCell>
                                    <StyledTableCell sx={{ maxWidth: 150, minWidth: 150 }}>
                                        <strong>Message</strong>
                                    </StyledTableCell>
                                    <StyledTableCell sx={{ maxWidth: 50, minWidth: 50 }}>
                                        <strong>View</strong>
                                    </StyledTableCell>

                                </TableRow>
                            </TableHead>
                            {data.map((data, index) => {
                                return (
                                    <TableBody>
                                        <StyledTableRow sx={{ borderBottomWidth: 1 }}>
                                            <StyledTableCell
                                                sx={{ maxWidth: 50, minWidth: 50 }}

                                            >
                                                {index + 1}
                                            </StyledTableCell>


                                            <StyledTableCell sx={{ maxWidth: 150, minWidth: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} >{data?.fullName}</StyledTableCell>
                                            <StyledTableCell sx={{ maxWidth: 150, minWidth: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} >{data?.number}</StyledTableCell>
                                            <StyledTableCell sx={{ maxWidth: 150, minWidth: 150, overflow: 'hidden', textOverflow: 'ellipsis' }} >{data?.email}</StyledTableCell>
                                            {data?.message === "" ?
                                                <StyledTableCell sx={{ maxWidth: 150, minWidth: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} >--</StyledTableCell>

                                                :
                                                <StyledTableCell sx={{ maxWidth: 150, minWidth: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} >{data?.message}</StyledTableCell>
                                            }




                                            <StyledTableCell sx={{ maxWidth: 50, minWidth: 50 }}>
                                                <IconButton aria-label="edit" size="large" color="info" onClick={() => handleView(data)}>
                                                    <VisibilityIcon />
                                                </IconButton>

                                            </StyledTableCell>


                                        </StyledTableRow>
                                    </TableBody>
                                )
                            })}
                        </Table>

                    </TableContainer>

                </div >
                <Modal
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <div className="dashboard">
                        <Box sx={style}>
                            <main className="mt-2">
                                <div className="mb-4 md:mb-0 w-full mx-auto relative" onClick={handleClose}>
                                    <div className="closeIcon right">
                                        <IconButton aria-label="edit" color="inherit" className="btnIcon-style" >
                                            <CloseIcon sx={{ fontSize: 16 }} />
                                        </IconButton>
                                    </div>
                                </div>

                                <table class="User-Table Table w-full">
                                    <tbody>
                                        <tr>
                                            <td className="Userinfo">Full Name</td>
                                            <td className="UserDip">{detail?.fullName}</td>
                                        </tr>
                                       
                                        <tr>
                                            <td className="Userinfo">Phone Number</td>
                                            <td className="UserDip">{detail?.number}</td>
                                        </tr>
                                        <tr>
                                            <td className="Userinfo">Email</td>
                                            <td className="UserDip">{detail?.email}</td>
                                        </tr>
                                        <tr>
                                            <td className="Userinfo">Message</td>
                                            {
                                                detail?.message === "" ?

                                                    <td className="UserDip">--</td>
                                                    :
                                                    <td className="UserDip">{detail?.message}</td>
                                            }
                                        </tr >

                                    </tbody >
                                </table >

                            </main >

                        </Box >
                    </div >
                </Modal >

            </div >
        </>
    );
};

export default Contact;
