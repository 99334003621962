import React, { useState } from 'react'
import servicesData from "../../data/cities.json" 
import { Link } from 'react-router-dom';
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { windowScroll } from '../../helpers/ScrollToTop';




const Cities = () => {
    const [view, setView] = useState(false)

    return (
        <>
            <div className="w-full md:pb-[70px] max-md:pb-[40px]">
                <div className="container mx-auto mt-10 md:mt-0">
                    <div>
                        <h2 className="text-center mb-[20px]">Popular States/Cities</h2>
                        <div className="flex shrink grow flex-wrap justify-center md:gap-[20px] max-md:gap-[10px]">
                            {servicesData?.map((item, index) => (
                                <div className="text-center md:w-[150px] max-md:w-[110px]">
                                    <div>
                                        <Link to={`/${item.types}`} onClick={windowScroll}>
                                            <img className="w-full md:max-w-[120px] max-md:max-w-[80px] mx-auto" src={item?.icon} alt={item.alt} />
                                        </Link>
                                    </div>
                                    <div>
                                        <Link to={`/${item.types}`} onClick={windowScroll}>
                                            <p className='md:text-[16px] max-md:text-[14px]'>{item?.cityname}</p>
                                        </Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            {view ?
                <div className="w-full md:pb-[70px] max-md:pb-[40px]">
                    <div className="container mx-auto">
                        <div>
                            <div className='text-center'>
                                <button onClick={() => setView(false)} className="inline-flex justify-center items-center gap-[10px] duration-[400ms,700ms] px-[30px] py-3 bg-[#0B7132] hover:bg-[#ffffff] border-2 border-[#0B7132] hover:text-black hover:border-2 hover:border-[#0B7132] text-white rounded-full mb-[20px] cursor-pointer">
                                    Hide All States/Cities <span><FaChevronUp /></span> {/* <span><FaChevronUp /></span> */}
                                </button>
                            </div>
                            <div >

                                <div className='container  '>
                                    {/* <div className='text-center'>
                                        <button onClick={() => setView(true)} className="inline-flex justify-center items-center gap-[10px] duration-[400ms,700ms] px-[30px] py-3 bg-[#0B7132] hover:bg-[#ffffff] border-2 border-[#0B7132] hover:text-black hover:border-2 hover:border-[#0B7132] text-white rounded-full mb-[20px] cursor-pointer">
                                            Hide All Cities <span><FaChevronDown /></span>
                                        </button>
                                    </div> */}

                                    <div className="grid max-sm:grid-cols-1 max-md:grid-cols-2 max-lg:grid-cols-4 lg:grid-cols-7 gap-x-[20px] gap-y-[10px] max-w-full mx-auto pt-[10px] text-center  ">
                                        {servicesData?.map((item) => {
                                            return (
                                                <div>
                                                    <Link to={`/${item?.types}`} onClick={windowScroll}>
                                                        <p className=''>{item?.cityname}</p>
                                                    </Link>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className="w-full md:pb-[70px] max-md:pb-[40px]">
                    <div className="container mx-auto">
                        <div>
                            <div className='text-center'>
                                <button onClick={() => setView(true)} className="inline-flex justify-center items-center gap-[10px] duration-[400ms,700ms] px-[30px] py-3 bg-[#0B7132] hover:bg-[#ffffff] border-2 border-[#0B7132] hover:text-black hover:border-2 hover:border-[#0B7132] text-white rounded-full mb-[20px] cursor-pointer">
                                    View All States/Cities <span><FaChevronDown /></span> {/* <span><FaChevronUp /></span> */}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }


        </>
    )

}
export default Cities;