import React from 'react'
import { useState } from 'react'

const Content = () => {
    const [show, setShow] = useState(false);
    return (
        <section className='home-content-info md:pb-[70px] max-md:pb-[50px] md:px-[20px]'>
            <div className='container'>
                <span dangerouslySetInnerHTML={{ __html: show ? text : text.slice(0, 850) }} />
                <button onClick={() => setShow(prev => !prev)} className='text-[#0B7132] hover:underline cursor-pointer'>{show ? " Read Less" : " ... Read More"}</button>
            </div>
        </section>
    )
}
const text = `<h2>Experience Best EV Charging Station Services</h2>
    <p>myEV Point, the <b>EV charging station providers</b> are committed to promote sustainable transportation by offering users reliable <b>EV charging stations.</b> We, the <b>EV charging service providers</b> have established our charging stations at multiple strategic locations,  ensuring that all EV owners can access them hassle-free while on the go. </p>
    <p>It has been seen that in recent years, the demand for electric vehicles has grown significantly due to factors like increase in pollution caused by emission of harmful gases and rise in the prices of petrol and diesel, and due to this increasing demand, the establishment of more and more <b>electric car charging stations</b> has become need of the day, as people are needed to be provided with the facility of <b>electric vehicle charging stations</b> at short distances just like gas stations, so we, the <b>electric vehicle charging station providers</b> in the name of MyEV point, completely understand the requirement for robust and widespread <b>public EV charging stations</b> and are committed to contribute towards this revolution of sustainable transportation by providing EV owners with efficient, convenient and eco-friendly <b>electric cars charging stations.</b></p>
    <p>With our mission of supporting EV revolution, we have located our fast charging stations at various strategic and popular destinations such as parking lots, shopping malls, residential areas and along the highway, providing EV owners with easy access to EV charging service while on the go. We, at MyEV point electric vehicle charging station providers, have a vast network of both AC and DC charging stations, keeping in mind the diverse the varying needs of the customers.</p>
    <p>Moreover, our <b>EV charging stations</b> are equipped with advanced technology, providing safe, reliable and eco-friendly charging solutions to people. We ensure using renewable sources of energy in order to power our <b>electric car charging stations,</b> as we believe that sustainable transportation is not just about reducing carbon footprint but also about creating a cleaner and greener future. Join us today and contribute towards a sustainable future.</p>
    <h2>Types of Electric Vehicle Charging Stations</h2>
    <p>We, at myEV point <b>EV charging station service providers</b> offer a broad range of <b>electric car charging stations</b> to the users for the purpose of both home and public usage. We have designed our electric vehicle charging stations to be safe, reliable and efficient, so that you can charge your vehicle hassle free and easily. We provide both AC and <a href="https://www.myevpoint.in/services/dc-charging-station"><b>DC EV charging services</b></a> as per the varying needs of the customers.</p>
    <p>If you are a businessman and want to get our <b>electric car station</b> at your premises, we ensure  quick services and if you want to get it installed for your home, then also we got you covered. To get more information about our <b>EV charging station,</b> feel free to contact us any time.</p>
    <h3>AC EV Charging Stations</h3>
    <p>At MyEV point, our EV charging stations have been designed to offer convenient and efficient charging for the owners of electric vehicles. Our AC EV charging station services prove to be perfect for both residential and public places like shopping malls, parking lots, workplaces etc. Our <a href="https://www.myevpoint.in/services/ac-charging-station"><b>AC EV charging station services</b></a> near you are compatible with all the EV models, making ourselves a perfect solution for everyone.</p>
    <h3>DC Charging Stations</h3>
    <p>Our <b>DC public EV charging stations</b> offer high-speed charging solutions, proving themselves to be the perfect choice for those who require quick top-ups. Our charging stations are located at strategic locations like along the highways, urban centres and commercial hubs, ensuring easy access to the EV owners and proving perfect for long travels.</p>
    <h2>Our Products</h2>
    <p>As we are committed to provide top-tier services , our <b>electric charging station</b> solutions have been customised to meet the unique needs of the EV owners. Our charging stations are designed for both residential and commercial use and some of our products are mentioned below-</p>
    <h3>7.4 kW AC Charging Machine</h3>
    <p>It is perfect for installation at home, shopping centres, parking lots, roadside market areas etc and offers reliable and <b>EV charging services</b> for the daily needs of the EV owners.</p>
    <h3>22 kW AC Charging Machine</h3>
    <p>Our <b>22 kW AC electric vehicle charging station</b> which provides impeccable performance can be installed in urban centres, shopping malls, airports or in private locations.</p>
    <h3>30 kW DC Fast Charging Machine</h3>
    <p>Get maximum uptime with our DC fast charging machine which you can get installed in city centres, shopping malls, office spaces, hospitals or highway restaurants.</p>
    <h3>60 kW DC Fast Charging Machine</h3>
    <p>Get swift and reliable <b>EV charging services</b> with MyEV point 60 kW DC fast charging machine. You can get our machine installed along the Highways, Restaurants, Dhabas, Malls, Hospitals etc.</p>
    <p>At MyEV Point, we provide above mentioned EV charging infrastructure to our users to enhance their user experience. If you  have any doubts or want to have more information about our products and services or you want to <a href="https://www.myevpoint.in/products"><b>Buy EV charging station</b></a> equipment, you can visit our products page.</p>
    <h2>MyEV Point Electric Vehicle Charging Station App</h2>
    <p>MyEV point <b>electric charging station service providers</b> have come up with <a href="https://www.myevpoint.in/ev-charging-app"><b>MyEV point charging app</b></a>, which helps you manage all your electric vehicle needs efficiently. Our <b>EV charging app</b> has been designed to offer a wide range of features to our users, thereby enhancing their <b>EV charging experience</b>. Its user-friendly interface allows users to navigate through it easily, allowing them to easily locate not only MyEV point charging stations but also others. Other than this, with myEV Point, an electric vehicle charging station service provider’s app, you can check the availability and schedule your <b>electric vehicle charging</b>. It also allows customers 
to keep an eye on the charging status of their EV while doing their work rather than just waiting for the car to get charged. Install our electric vehicle charging app today and enhance your EV charging experience.</p>
    <h3>How to Use Our Electric Vehicle Charging App</h3>
    <p>The working of our <b>electric vehicle charging app</b> is very simple</p>
    <ul class="list_style">
        <li>Install MyEV point app.</li>
        <li>Create an account.</li>
        <li>Recharge your wallet.</li>
        <li>Now, you can locate charging stations.</li>
        <li>Scan QR code to start charging.</li>
    </ul>
    <h2>What Sets Us Apart?</h2>
    <p>MyEV point, the best <b>EV Charging Station Services Providers</b> are always committed to offer exceptional <b>Electric vehicle charging solutions</b> that help us to be at forefront in the industry. We also offer our uses with many other features which are mentioned below.</p>
    <ul class="list_style">
        <li><b>Quality and Affordability:</b> We ensure offering top-notch solutions which provide exceptional performance. Along with this, our <b>EV charging station cost</b> is affordable, proving perfect for every pocket.</li>
        <li><b>Comprehensive Charging Solutions:</b> Users are provided with a broad range of AC and DC <b>EV charging stations</b> to fulfil the varying needs of the owners of electric vehicles.</li>
        <li><b>Advanced Technology:</b> MyEV Point <b>electric vehicle charging stations</b> are equipped with state-of-the-art technology, thereby fast and reliable <b>EV charging services.</b></li>
        <li><b>Strategic Locations:</b> Our network of <b>EV charging stations</b> is widespread, ensuring easy accessibility for all the EV owners.</li>
        <li><b>Expert Team:</b> Our team highly skilled staff guarantees timely execution and completion of the projects.</li>
    </ul>
    <h2>Our Electric Vehicle Charging Stations Can Be Installed At</h2>
    <ul class="list_style">
        <li><b>Residences:</b> Install in your garage and ensure tomorrow’s eco-friendly neighbourhood.</li>
        <li><b>Workplaces:</b> Get installed in the parking and provide better employee satisfaction.</li>
        <li><b>Parking complexes:</b> Install our charging stations at strategic and excellent locations and provide easy accessibility to the users.</li>
        <li><b>Hotels:</b> Get our <b>EV charging station</b> installed at your premises and attract more customers.</li>
        <li><b>Restaurants:</b> Enhance your business by getting MyEV point charger installed at your restaurant and Dhaba.</li>
        <li><b>Dhabas:</b> Let customers get their vehicles charged while eating at your place and attract more and more customers.</li>
    </ul>
    <h2>Frequently Asked Questions</h2>
    <h4>How many types of EV charging stations are provided by MyEV Point?</h4>
    <p>Three types of EV charging station services are available called Level 1, Level 2 and Level 3.</p>
    <h4>How much does it cost to charge an Electric Vehicle from MyEV point charging station?</h4>
    <p>Although it totally depends upon the rates of electricity whether you charge at a station or home, some charging station providers charge according to start rate or sometimes rate per minute.</p>
    <h4>How can I find myEV point charging station near to me?</h4>
    <p>It is very easy, as we have established a proper network which could easily be accessed through our application.</p>
    <h4>Can I charge my vehicle in the rain with the MyEV Point charging station?</h4>
    <p>Definitely yes because our charging ports are weather resistant allowing users to charge their EVs in any weather condition.</p>
    <h4>How much maintenance do the chargers of MyEV point need?</h4>
    <p>Although these are advanced technology gadgets, they are very simple to maintain and thus require very little maintenance.</p>
    <h4>How much area is required to install MyEV point charging station?</h4>
    <p>For a single charging point installation, a very small area of nearly 12 sq metres is enough.</p>
    <h4>Which charging station is better for EV in India?</h4>
    <p>It totally depends upon the needs of the user. If a user has to go on a long journey, he must give preference to Level 3 DC fast charging. On the other hand, if one uses a car for regular short journeys, one can go for level 1 and level 2.</p>
    <h4>Which type of connectors are used by myEV point for AC charging?</h4>
    <p>For AC charging, type 2 connectors are used.</p>
    <h4>Why is home EV charging given more preference over Public EV charging?</h4>
    <p>It is basically due to the fact that in the past, the availability of public EV charging stations was very less and one another reason is the convenience of charging at home.</p>
    <h4>Is it common for EV charging to run out before I find a station?</h4>
    <p>There is no need to worry as the mechanism is the same as of gasoline powered old vehicles. Your vehicle will give you warning about the low charging and you can have a lot of time to find a charging station near you.</p>
    <h4>Why do EV charging stations need the internet?</h4>
    <p>They need the internet in order to set the schedules, track data and to charge users according to the electricity.</p>
    <h4>Any tips for best EV charging practice?</h4>
    <p>Yes, you should always make sure to keep your charging status between 25% and 75%.</p>
    <h4>Do all electric vehicles have a common plug?</h4>
    <p>For level 1 and level 2 charging, standard connectors are used but for DC charging connectors are different depending upon the make and model of your EV.</p>
    <h4>What is the maximum voltage for EV?</h4>
    <p>It typically ranges between 400-800V for lithium-ion battery packs.</p>
    <h4>Will my electricity bill go up? </h4>
    <p>Yes, it will be a bit more but similarly you can save more money that you pay at public charging stations. Moreover, by charging at night, you can take advantage of low electricity rates.</p>
    <h4>What if I have more questions?</h4>
    <p>If you have any doubt regarding myEV point, EVs, charging stations or anything, feel free to contact us.</p>`

export default Content;