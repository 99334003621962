import myEV_logo from "../assets/Images/myEV_logo.webp";
import aboutUs_car from "../assets/Images/aboutUs_car.webp";
import Rectangle from "../assets/Images/Rectangle.webp";
import feature_bg from "../assets/Images/feature_bg.webp";
import bannerimage from "../assets/Images/bg-img-hero.webp";
import car from "../assets/Images/car.webp";
import blueBg from "../assets/Images/blueBg.webp"
import Ellipse from "../assets/Images/Ellipse.webp"
import facebook from "../assets/Images/facebook.webp";
import footer_myEV_logo from "../assets/Images/footer_myEV_logo.webp";
import Polygon from "../assets/Images/Polygon.webp";
import location from "../assets/Images/location.webp";
import clock from "../assets/Images/clock.webp";
import earth from "../assets/Images/earth.webp";
import filling_machine from "../assets/Images/filling_machine.webp";
import shape1 from "../assets/Images/shape1.webp";
import shape2 from "../assets/Images/shape2.webp";
import petrol from "../assets/Images/petrol.webp";
import map from "../assets/Images/map.webp";
import roundedimage from "../assets/Images/roundedimage.webp";
import chargingsession from "../assets/Images/chargingsession.webp";
import services_banner from "../assets/Images/services_banner.webp";
import services1 from "../assets/Images/services11.webp";
import services2 from "../assets/Images/services2.webp";
import right_arrow from "../assets/Images/right_arrow.webp";
import work from "../assets/Images/work.webp";
import work1 from "../assets/Images/work1.webp";
import work2 from "../assets/Images/work2.webp";
import work3 from "../assets/Images/work3.webp";
import pdf from "../assets/Images/pdf.webp";
import document from "../assets/Images/document.webp";
import mapsection from "../assets/Images/mapsection.webp";
import contact_banner from "../assets/Images/contact_banner.webp";
import Rectangle1 from "../assets/Images/Rectangle1.webp";
import Aboutimage from "../assets/Images/Aboutimage.webp";
import percentage from "../assets/Images/percentage.webp";
import vision from "../assets/Images/vision.webp";
import mission from "../assets/Images/mission.webp";
import pattern from "../assets/Images/pattern.webp";
import leftarrow from "../assets/Images/leftarrow.webp"
import rightarrow from "../assets/Images/rightarrow.webp"
import footer_right_bg from "../assets/Images/footer-right-bg.webp"
import footer_left_bg from "../assets/Images/footer-left-bg.webp"
import beforeimg from "../assets/Images/before-img.webp"
import afterimg from "../assets/Images/after-img.webp"
import EVCharging from "../assets/Images/ev-machine.webp"
import EVCharging1 from "../assets/Images/About_E_Charge.webp"
import marker from "../assets/Images/marker.webp"
import evmachines from "../assets/Images/evmachines.webp"
import filling from "../assets/Images/services15.webp"
import loading from "../assets/Images/loading.webp"
import icon1 from "../assets/Images/icon1.webp"
import icon2 from "../assets/Images/icon2.webp"
import icon3 from "../assets/Images/icon3.webp"
import logobgtransparent from "../assets/Images/logo-bg-transparent.webp"
import EVMachine7 from "../assets/Images/EV Machine7.4.webp"
import MyEVMachine7 from "../assets/Images/MyEVMachine7.4.webp"
import EVMachine22 from "../assets/Images/My EV Point 22 KW Machine.webp"
import MyEVMachine22 from "../assets/Images/22 KW Electric Vehicle Machine.webp"
import EVMachine30 from "../assets/Images/Untitled design (82).webp"
import MyEVMachine30 from "../assets/Images/My EV Point 30 KW Charging Station Machine.webp"
import EVMachine60 from "../assets/Images/My EV Point 60 KW Machine.webp"
import MyEVMachine60 from "../assets/Images/Electric Vehicle Machine.webp"
import EVShipping from "../assets/Images/EV Shipping.png"
import Delhi from "../assets/Images/icons_Delhi.png"
import Chandigarh from "../assets/Images/icons_Chandigarh.png"
import Haryana from "../assets/Images/icons_haryana.png"
import Mumbai from "../assets/Images/icons_Mumbai.png"
import Punjab from "../assets/Images/icons_punjab.png"
import Shimla from "../assets/Images/icons_Shimla.png"
import UP from "../assets/Images/icons_Up.png"
import hiring from "../assets/Images/hiring.webp"



const ImageIcons = {
  EVCharging,
  beforeimg,
  afterimg,
  myEV_logo,
  aboutUs_car,
  Rectangle,
  feature_bg,
  bannerimage,
  car,
  facebook,
  footer_myEV_logo,
  Polygon,
  location,
  blueBg,
  Ellipse,
  filling_machine,
  clock,
  earth,
  shape1,
  shape2,
  petrol,
  map,
  roundedimage,
  chargingsession,
  services_banner,
  services1,
  services2,
  right_arrow,
  work,
  work1,
  work2,
  work3,
  pdf,
  document,
  mapsection,
  contact_banner,
  Rectangle1,
  Aboutimage,
  percentage,
  vision,
  mission,
  pattern,
  rightarrow,
  leftarrow,
  footer_right_bg,
  footer_left_bg,
  EVCharging1,
  marker,
  evmachines,
  filling,
  loading,
  icon1,
  icon2,
  icon3,
  logobgtransparent,
  EVMachine7,
  MyEVMachine7,
  EVMachine22,
  MyEVMachine22,
  EVMachine30,
  MyEVMachine30,
  EVMachine60,
  // MyEVMachine30,
  MyEVMachine60,
  EVShipping,
  Delhi,
  Haryana,
  Chandigarh,
  Mumbai,
  Punjab,
  Shimla,
  UP,
  hiring

}

export default ImageIcons;
