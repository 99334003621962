import React from 'react';
import Header from "../components/header/Header";
import PrivacyPolicy from "../components/termsPrivacy/Privacy";
import Footer from "../components/footer/Footer";
import SEO from "../common/SEO";
import aboutBanner from "../assets/Images/Privacy&Policy.webp";
import InnerPageBanner from '../components/shared/InnerPageBanner';

const Privacy = () => {

    return (
        <>
            <SEO title='MyEvPoint Privacy Policy | EV Charging Policies' description='MyEvPoint Privacy Policy for transparent electric vehicle charging policies. Learn about data protection and usage guidelines for a secure charging experience.' />
            <Header />
            <InnerPageBanner title='Privacy Policy' maxWidth='472px' innerPageBanner={aboutBanner} />
            <PrivacyPolicy />
            <Footer />
        </>
    )
}
export default Privacy;