import React from 'react'
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import SEO from "../common/SEO";
import InnerPageBanner from '../components/shared/InnerPageBanner';
import aboutBanner from "../assets/Images/Cities.webp";
import Cities from "../components/cities/Cities";
import Search from "../components/search/Search";

const City = () => {

    return (
        <>
            <SEO title="Explore Citations for EV Charging Stations | My EV Point" description="Discover insightful citations on electric vehicle charging stations. Find information, tips, and guidance. Explore now at myevpoint.in!" />
            <Header />
            <InnerPageBanner
                title="States"
                innerPageBanner={aboutBanner}
            />
            <Search />
            <Cities />
            <Footer />
        </>
    )
}
export default City;