import React, { useState, useEffect } from "react";
import ImageIcons from "../../common/ImageIcons";
import { useParams } from 'react-router-dom';
import servicesData from "../../data/services.json"


const Chargers = () => {


    const { type } = useParams();


    const [serviceDetails, setServiceDetails] = useState(null);

    useEffect(() => {
        const selectedService = servicesData.find(service => service.type.includes(type));
        setServiceDetails(selectedService);
    }, [type]) 
    

    const [isChecked, setIsChecked] = useState(false);
    const handleToggle = () => {
        setIsChecked(!isChecked);
    };
    const FeatureList = ({ item }) => (
        <div className='mb-3 flex flex-col '>
            <div className="flex flex-row items-start">
                <img src={ImageIcons.Polygon} alt="Polygon" />
                <p className='ml-3 font-medium'>{item.text}</p>
            </div>
        </div>
    );

    return (
        <>
            <div className='fast-slow-charger-info mt-10'>
                {serviceDetails?.type === 'ac-charging-station' ?
                    <div className="bg-[#F1F8E8] py-8 ">
                        <div className="xl:mb-2 px-2">
                            <div className="flex flex-row justify-center px-6 mx-auto items-center">
                                <p className="mt-7 text-base md:text-lg lg:text-2xl font-medium text-[#000] text-center">Fast Charging Setup</p>
                                <div className="mt-6 sm:mt-8 md:px-6 max-md:px-3">
                                    <label className="relative inline-flex cursor-pointer items-center ">
                                        <input id="switch"
                                            type="checkbox"
                                            className="peer sr-only "
                                            checked={isChecked}
                                            onChange={handleToggle}
                                        />
                                        <label for="switch" className="hidden"></label>
                                        <div className="peer h-7 w-14 rounded-full border bg-[#CCCCCC] after:absolute after:left-[8px] after:top-1 after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-[#0B7132] peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:ring-green-300"></div>
                                    </label>
                                </div>
                                <p className="mt-7 text-base md:text-lg lg:text-2xl font-medium text-[#000] text-center">Slow Charging Setup</p>
                            </div>
                            {isChecked ?
                                <div className='flex flex-col w-11/12 justify-between mx-auto md:flex-row mb-0 md:mt-8'>
                                    <div className="py-12 md:w-5/12 md:mt-8 lg:mx-0 lg:w-8/12">
                                        <h3 className="text-left text-black font-semibold mb-10 md:mb-10">Slow Charging Setup</h3>

                                        {serviceDetails?.slowFeatures.map((item) => (
                                            <FeatureList key={item.id} item={item} />
                                        ))}
                                    </div>
                                    <div className="md:w-7/12 lg:w-4/12 mx-auto lg:mx-0 items-center my-auto">
                                        <img src={ImageIcons.evmachines} alt="ac and dc ev charging stations" className='rounded-2xl max-w-[200px] m-auto' />
                                    </div>
                                </div>
                                :
                                <div className='flex flex-col w-11/12 justify-between mx-auto md:flex-row mb-0  md:mt-8'>
                                    <div className="py-12 md:w-5/12 md:mt-8 lg:mx-0 lg:w-8/12">
                                        <h3 className="text-left text-black font-semibold mb-10 md:mb-10">Fast Charging Setup</h3>

                                        {serviceDetails?.fastFeatures.map((item) => (
                                            <FeatureList key={item.id} item={item} />
                                        ))}
                                    </div>
                                    <div className="md:w-7/12 lg:w-4/12 mx-auto lg:mx-0 items-center my-auto">
                                        <img src={ImageIcons.evmachines} alt="ac and dc ev charging stations" className='rounded-2xl max-w-[200px] m-auto' />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    :
                    <div className="bg-[#F1F8E8] py-8 ">
                        <div className="xl:mb-2 px-2">
                            <div className="flex flex-row justify-center px-6 mx-auto items-center">
                                <p className="mt-7 text-base md:text-lg lg:text-2xl font-medium text-[#000] text-center">Single Charging Setup</p>
                                <div className="mt-6 sm:mt-8 md:px-6 max-md:px-3">
                                    <label className="relative inline-flex cursor-pointer items-center ">
                                        <input id="switch"
                                            type="checkbox"
                                            className="peer sr-only "
                                            checked={isChecked}
                                            onChange={handleToggle}
                                        />
                                        <label for="switch" className="hidden"></label>
                                        <div className="peer h-7 w-14 rounded-full border bg-[#CCCCCC] after:absolute after:left-[8px] after:top-1 after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-[#0B7132] peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:ring-green-300"></div>
                                    </label>
                                </div>
                                <p className="mt-7 text-base md:text-lg lg:text-2xl font-medium text-[#000] text-center">Dual Charging Setup</p>
                            </div>
                            {isChecked ?
                                <div className='flex flex-col w-11/12 justify-between mx-auto md:flex-row mb-0 md:mt-8'>
                                    <div className="py-12 md:w-5/12 md:mt-8 lg:mx-0 lg:w-8/12">
                                        <h3 className="text-left text-black font-semibold mb-10 md:mb-10">Dual Charging Setup</h3>

                                        {serviceDetails?.dualFeatures.map((item) => (
                                            <FeatureList key={item.id} item={item} />
                                        ))}
                                    </div>
                                    <div className="md:w-7/12 lg:w-4/12 mx-auto lg:mx-0 items-center my-auto">
                                        <img src={ImageIcons.evmachines} alt="ac and dc ev charging stations" className='rounded-2xl max-w-[200px] m-auto' />
                                    </div>
                                </div>
                                :
                                <div className='flex flex-col w-11/12 justify-between mx-auto md:flex-row mb-0  md:mt-8'>
                                    <div className="py-12 md:w-5/12 md:mt-8 lg:mx-0 lg:w-8/12">
                                        <h3 className="text-left text-black font-semibold mb-10 md:mb-10">Single Charging Setup</h3>

                                        {serviceDetails?.singleFeatures.map((item) => (
                                            <FeatureList key={item.id} item={item} />
                                        ))}
                                    </div>
                                    <div className="md:w-7/12 lg:w-4/12 mx-auto lg:mx-0 items-center my-auto">
                                        <img src={ImageIcons.evmachines} alt="ac and dc ev charging stations" className='rounded-2xl max-w-[200px] m-auto' />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        </>
    )
};






export default Chargers;