import React from 'react';


const Videos = () => {
 

  return (
    <>
      {/* Gallery Page */}
      <div className="gallery-section md:py-20 max-md:py-[50px] relative">
        <div className="container mx-auto px-2">
          <div className="grid max-md:grid-cols-1 max-lg:grid-cols-2 lg:grid-cols-3 md:gap-[30px] max-md:gap-[15px] pb-2 mx-auto">
            <div className='relative p-[28%] w-full overflow-hidden'>
              <iframe className='absolute top-0 left-0 w-full h-full object-center object-cover'  src="https://www.youtube.com/embed/QraLIsumt0U?si=6J2_jt59XStVQLFa" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            <div className='relative p-[28%] w-full overflow-hidden'>
              <iframe className='absolute top-0 left-0 w-full h-full object-center object-cover' src="https://www.youtube.com/embed/a0mDIPbZtNo?si=g9-SLkEi8F7jb_2i" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </div>
      {/* 
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ backdropFilter: "blur(7px)" }}
      >
        <div>
          <div className="absolute md:left-5 max-md:left-2 top-1/2">
            <ChevronLeftIcon className='text-[#fff] hover:text-[#0B7132] max-md:!w-[35px]' onClick={gotoNext} sx={{ fontSize: 70, cursor: 'pointer' }} />
          </div>
          <Box sx={style} className="focus:outline-none focus-visible:outline-none max-md:w-[75%]">
            <div className='flex flex-row items-center'>
              <video
                className='w-full md:h-full md:object-contain md:max-h-[90vh]'
                controls
              >
                <source src={gallery[currentVideoIndex]?.video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </Box>
          <div className="absolute md:right-5 max-md:right-2 top-1/2">
            <ChevronRightIcon className='text-[#fff] hover:text-[#0B7132] max-md:!w-[35px]' onClick={gotoNext} sx={{ fontSize: 70, cursor: 'pointer' }} />
          </div>
        </div>
      </Modal> */}
    </>
  );
};

export default Videos;

