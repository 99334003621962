import React from 'react'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import InnerPageBanner from '../components/shared/InnerPageBanner';
import aboutBanner from "../assets/Images/EVChargingGuide.webp";
import SEO from '../common/SEO';
import ContactInfo from '../components/contact_info/ContactInfo';
import ChargingGuides from '../components/chargingguide/ChargingGuide'

const ChargingGuide = () => {

  return (
    <>
      <SEO title="Charge Your Electric Car with My EV Point" description='Step by step guide how to conveniently charge your electric vehicle with My EV Point. Discover easy charging solutions for your EV needs.' keywords='Electric Car Charging GuideEV Charging Guide,How to Charge Your Car,Electric Vehicle Charging Tips,EV Charger Installation Guide'/>
      <Header />
      <InnerPageBanner title='Charging Guide' maxWidth='472px' innerPageBanner={aboutBanner} />
      <ChargingGuides />
      <ContactInfo />
      <Footer />
    </>
  )
}

export default ChargingGuide
