import React from "react";
import { FaToolbox, FaMoneyBillAlt, FaUsersCog, FaVial, FaAward, FaRecycle  } from "react-icons/fa";
// import GalleryPage from '../../components/gallery/GalleryPage';
import Counting from "../../components/counting/Counting";
import Images from "../images/Images";

const Partners = () => {
    return(

        <>
            <div className="w-full pt-20 pb-5">
                <div className="container mx-auto px-2">
                    {/* <h2 class="text-center md:pb-12 max-md:pb-8 text-[#000]">Join Our EV Charging Network</h2> */}
                    <div className="flex md:gap-10 max-md:gap-10 max-md:flex-col mb-10">
                        <div className="md:w-1/3">
                            <div>
                                <div className="text-[#0B7132] bg-[#F1F8E8] flex items-center justify-center w-[80px] h-[80px] rounded-full shadow-[0_3px_10px_5px_rgba(0,0,0,0.1)] mx-auto mb-3">
                                    <FaToolbox className="text-[32px]" />
                                </div>
                                <div className="text-center">
                                    <h4 className="mb-1">Lucrative Revenue</h4>
                                    <p className="text-sm">Open up a world of lucrative revenue opportunities by catering to the growing market of EV.</p>
                                </div>
                            </div>
                        </div>
                        <div className="md:w-1/3">
                            <div>
                                <div className="text-[#0B7132] bg-[#F1F8E8] flex items-center justify-center w-[80px] h-[80px] rounded-full shadow-[0_3px_10px_5px_rgba(0,0,0,0.1)] mx-auto mb-3">
                                    <FaMoneyBillAlt className="text-[32px]" />
                                </div>
                                <div className="text-center">
                                    <h4 className="mb-1">Competitive Pricing</h4>
                                    <p className="text-sm">Get competitive pricing and attractive discounts and maximise your profits along with offering competitive rates to the customers.</p>
                                </div>
                            </div>
                        </div>
                        <div className="md:w-1/3">
                            <div>
                                <div className="text-[#0B7132] bg-[#F1F8E8] flex items-center justify-center w-[80px] h-[80px] rounded-full shadow-[0_3px_10px_5px_rgba(0,0,0,0.1)] mx-auto mb-3">
                                    <FaUsersCog className="text-[32px]" />
                                </div>
                                <div className="text-center">
                                    <h4 className="mb-1">Technical Expertise and Training</h4>
                                    <p className="text-sm">Enjoy access to our technical training and get equipped with knowledge and skills required to run your business.</p>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className="flex md:gap-10 max-md:gap-10 max-md:flex-col md:mb-5">
                        <div className="md:w-1/3">
                            <div>
                                <div className="text-[#0B7132] bg-[#F1F8E8] flex items-center justify-center w-[80px] h-[80px] rounded-full shadow-[0_3px_10px_5px_rgba(0,0,0,0.1)] mx-auto mb-3">
                                    <FaVial className="text-[32px]" />
                                </div>
                                <div className="text-center">
                                    <h4 className="mb-1">Extensive product Portfolio</h4>
                                    <p className="text-sm">Get access to our extensive portfolio of high quality products. Our diverse offerings allow you to serve varying needs and preferences.</p>
                                </div>
                            </div>
                        </div>
                        <div className="md:w-1/3">
                            <div>
                                <div className="text-[#0B7132] bg-[#F1F8E8] flex items-center justify-center w-[80px] h-[80px] rounded-full shadow-[0_3px_10px_5px_rgba(0,0,0,0.1)] mx-auto mb-3">
                                    <FaAward className="text-[32px]" />
                                </div>
                                <div className="text-center">
                                    <h4 className="mb-1">Brand Recognition</h4>
                                    <p className="text-sm">Our earned name in the market provides you with a competitive advantage and reputation in the industry.</p>
                                </div>
                            </div>
                        </div>
                        <div className="md:w-1/3">
                            <div>
                                <div className="text-[#0B7132] bg-[#F1F8E8] flex items-center justify-center w-[80px] h-[80px] rounded-full shadow-[0_3px_10px_5px_rgba(0,0,0,0.1)] mx-auto mb-3">
                                    <FaRecycle className="text-[32px]" />
                                </div>
                                <div className="text-center">
                                    <h4 className="mb-1">Uplift Green Mobility</h4>
                                    <p className="text-sm">Invest in electric vehicle charging infrastructure and contribute towards building a cleaner, greener and more sustainable tomorrow.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="container mx-auto px-2">
                    <Counting/>
                </div>
            </div>
            {/* <div className="w-full">
                <div className="container !max-w-[900px] mx-auto px-2">
                    <h3 class="mb-[15px] text-center">Empower Sustainable Transportation: Pave the Way for a Greener Tomorrow</h3>
                    <p className="mb-[15px] text-center">Without a iota of doubt, to reduce carbon footprint and to combat climate change, it is necessary to look for sustainable transportation solutions and in this quest, embracing the EVs along with investing in EV charging infrastructure seems to be one of the greatest steps towards achieving the goal.</p>
                    <p className="mb-[15px] text-center">It has also been predicted that as the usage of electric vehicles is growing, EV market will see a significant boost in the future. This represents huge opportunities for businesses to invest into a rapidly growing market and secure a profitable future because of potential for significant returns on investment (ROI). Moreover, as the demand for EV charging service is rising, especially in urban areas and along major transportation highways, owners can reap huge financial benefits from their investment.</p>
                    <p className="text-center">Moreover, investing in EV charging infrastructure goes along with sustainability initiatives. Therefore, by supporting electric transportation by providing charging infrastructure, station owners can establish themselves as leaders in sustainability and contribute in efforts towards reducing greenhouse gas emission and combating climatic shifts.</p>
                </div>
            </div> */}
            <Images/>
        </>
    )

}
export default Partners;