import React from "react";


const Oursolutions = () => {
    return(

        <>
        <p>Oursolutions</p>
        </>
    )

}
export default Oursolutions;