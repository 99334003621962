import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import VerifiedIcon from '@mui/icons-material/Verified';
import Button from "@mui/material/Button";
import axios from "axios";
import Loader from "../Loader";
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 292,
    bgcolor: 'background.paper',

    border: '1px solid #000',
    p: 4,
    textAlign: 'center'
};

const AddGallery = ({ setTrigger }) => {




    const handleImageChange = (e) => {
        const selectedFile = e.target.files[0];

        // Check if the selected file is a zip file
        if (selectedFile && !selectedFile.type.startsWith("image/")) {
            setError(prevState => ({ ...prevState, success: false, message: "Please select a valid image file." }));
            return;
        }

        // Set the image if it's not a zip file
        setImage(selectedFile);
        e.target.value = null;
    };

    const handleTitleChange = (e) => {
        const inputText = e.target.value;
        setTitle(inputText);
    };

    const handleAltChange = (e) => {
        const inputText = e.target.value;
        setAlt(inputText);
    };


    const [open, setOpen] = useState(false);

    const handleClose = () => { setOpen(false) }


    const [title, setTitle] = useState('');
    const [image, setImage] = useState(null);
    const [alt, setAlt] = useState(null);
    // const [description, setDescription] = useState('');

    const [error, setError] = useState({
        handle: false,
        message: "",
        success: false
    });

    const [handleError, setHandleError] = useState('');
    const [disabled, setDisabled] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault()
        setDisabled(true);
        setError(prevState => ({ ...prevState, message: "" }));
        if (!title || !image || !alt) {
            setError(prevState => ({ ...prevState, success: false, message: "Fields must not be empty!" }));
            setDisabled(false);

        } else {
            const formDataToSend = new FormData();
            formDataToSend.append("title", title);
            formDataToSend.append("image", image);
            formDataToSend.append("alt", alt);

            console.log("formdata",formDataToSend)
            try {
                // setError(prevState => ({ ...prevState, success: false }));
                const response = await axios.post(`${process.env.REACT_APP_URL}/user/addGallery`, formDataToSend);
                console.log("responsedata",response.data)

                setTimeout(() => {
                    setDisabled(false);
                    setTitle('');
                    setAlt('');
                    setImage(null);
                }, 1000);
                setTrigger(prev => prev + 1);
            } catch (error) {
                setHandleError(error.response.data.message)
                setDisabled(false);
            }
        }
    };

    return (
        <>
            <div className="Categories-form relative z-9 bg-white mx-auto grid rounded-[10px]">
                <h1 className="uppercase">gallery</h1>
                {disabled && (<Loader />)}
                <form

                    onSubmit={handleSubmit}
                >
                    {handleError ?
                        <p className={`text-[14px] text-left text-[#FF0000] font-semibold mb-[10px] ${handleError ? 'block' : 'hidden '}`}>Error: {handleError}</p>

                        :
                        <p className={`text-[14px] text-left text-[#FF0000] font-semibold mb-[10px] ${error.message ? 'block' : 'hidden '}`}>Error: {error.message}</p>
                    }
                    <Grid className="mb-3" container spacing={2} md={8}>
                        <Grid item md={12}>

                            <label className="form-label">Title</label>
                            <input
                                disabled={disabled}
                                className="inpit_row form-input"
                                name="title"
                                type="text"
                                value={title}
                                onChange={handleTitleChange}
                                placeholder="Enter Title"
                                maxLength={30}
                            />

                        </Grid>
                        <Grid item md={12}>
                            <label className="form-label">Alt Tag</label>
                            <input
                                disabled={disabled}
                                className="inpit_row form-input"
                                name="alt"
                                type="text"
                                value={alt}
                                onChange={handleAltChange}
                                placeholder="Enter Alt"
                                maxLength={30}
                            />

                        </Grid>

                        {/* {image && (
                            <div>
                                <img src={URL.createObjectURL(image)} alt="Selected Image" width="100" />
                            </div>
                        )} */}
                        <Grid item md={6}>

                            <label className="form-label">Image</label>

                            <div className="flex flex-row">
                                <Button component="label" variant="contained" startIcon={<CloudUploadIcon />} onChange={(e) => handleImageChange(e)} >
                                    Upload file
                                    <VisuallyHiddenInput type="file" accept="image/png, image/jpg, image/jpeg,image/svg,image/webp" />
                                </Button>
                                <div className="ml-2 mt-1 ">
                                    {image ? <p className="text-base">{image?.name}</p> : <p className="text-base">no file chosen</p>}
                                </div>
                                
                            </div>
                            
                        </Grid>
                    </Grid>

                    <button className="form-action w-60 " type="Submit">
                        Add
                    </button>
                </form>
                <Modal
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="keep-mounted-modal-title" variant="h6" component="h2" style={{ paddingTop: "20px" }}>
                            Add Successfully <VerifiedIcon style={{ position: "absolute", top: "20px", left: "46%", color: "#1aa13e", fontSize: "30px" }} />
                        </Typography>
                    </Box>
                </Modal>
            </div>
        </>
    );
    ;
}

export default AddGallery;
