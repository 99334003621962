import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import SEO from "../common/SEO";
import InnerPageBanner from '../components/shared/InnerPageBanner';
import aboutBanner from "../assets/Images/bannerimage.webp";
import Oursolutions from "../components/oursolutions/Oursolutions";

const Oursolution = () => {
    return (
        <>
            <SEO title=' Partner with MyEVPoint: Join Our EV Charging Network' description='Partnership opportunity with MyEVPoint. Join our EV charging network and be part of the future of sustainable transportation. Contact us today' />
            <Header />
            <InnerPageBanner
                title='Oursolutions'
                innerPageBanner={aboutBanner}
            />
            <Oursolutions/>
            <Footer />
        </>
    )
}
export default Oursolution;